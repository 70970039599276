/**
 * Converts a hex color to an HSL object.
 * @param {string} hex - The hex color (e.g., "#0C5CA8").
 * @returns {Object} The HSL representation of the color.
 */
function hexToHsl(hex) {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;
  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return {
    h: Math.round(h * 360),
    s: s * 100,
    l: l * 100,
  };
}

/**
 * Converts an HSL object to a hex color.
 * @param {Object} hsl - The HSL object (e.g., {h: 210, s: 60, l: 70}).
 * @returns {string} The hex color.
 */
function hslToHex({ h, s, l }) {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r, g, b;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  return `#${[r + m, g + m, b + m]
    .map((x) =>
      Math.round(x * 255)
        .toString(16)
        .padStart(2, "0")
    )
    .join("")}`;
}

/**
 * Lightens an HSL color by a specified amount, with a max lightness limit.
 * @param {Object} hsl - The HSL color to lighten.
 * @param {number} percent - The percentage to lighten the color by.
 * @returns {Object} The lightened HSL color.
 */
function lightenHsl({ h, s, l }, percent) {
  // Increase lightness while ensuring it doesn't exceed 90% to keep visibility
  return {
    h,
    s,
    l: Math.min(96, l + percent),
  };
}

/**
 * Processes the color to return its lightened version.
 * @param {string} color - The color to process (e.g., "#0C5CA8").
 * @returns {string} The lightened color in hex format.
 */
export function processColor(color) {
  const hsl = hexToHsl(color);
  const lightenedHsl = lightenHsl(hsl, 99); // Lighten by 50%
  const lightened50 = lightenHsl(hsl, 50); // Lighten by 50%
  return {
    backgroundColor: hslToHex(lightenedHsl),
    borderTopColor: hslToHex(lightened50),
  };
}

// // Example usage:
// const color = "#0C5CA8";
// const lightenedColor = processColor(color);
// console.log(lightenedColor); // Example output: #B6D4F4
