import React from "react";
import WhatsappContact from "../../assets/svgs/WhatsappContact";
import { useSelector } from "react-redux";

const WhatsappButton: React.FC = () => {
  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  const opneWhatsapp = () => {
    var phoneNumber = bussinessData?.businessWhatsapp;
    var message = `Hello, 
I saw a product on your website that I'm interested in. 
Can you provide more details?`;
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, "_blank");
  };
  // href="https://wa.me/+201203110365?text=welcome"
  return (
    <button
      // aria-label="Scroll to top"
      type="button"
      className="whats-contact-wrapper"
      onClick={opneWhatsapp}
    >
      <WhatsappContact />
    </button>
  );
};

export default WhatsappButton;
