import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";
import { useEffect } from "react";
import { saveUserPrefersLang } from "../../../shared/helpersMethod";
import ArrowDownSvg from "../../../assets/svgs/ArrowDownSvg";
const MobileLangCurrChange = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const currency = useSelector((state) => state.currency);

  // const changeLanguageTrigger = e => {
  //   const languageCode = e.target.value;
  //   i18n.changeLanguage(languageCode);
  //   document
  //   .getElementsByTagName("html")[0]
  //   .setAttribute("lang", languageCode === "ar" ? "ar" : "en");
  // document
  //   .getElementsByTagName("body")[0]
  //   .setAttribute("dir", languageCode === "ar" ? "rtl" : "ltr");
  //   closeMobileMenu();
  // };
  const changeLanguageTrigger = (e) => {
    // console.log(e.target.value);
    const languageCode = e.target.value;

    i18n.changeLanguage(languageCode);
    if (languageCode === "ar") {
      // GoogleTranslate();
    }
    saveUserPrefersLang(languageCode);

    document
      .getElementsByTagName("html")[0]
      .setAttribute("lang", languageCode === "ar" ? "ar" : "en");
    document
      .getElementsByTagName("body")[0]
      .setAttribute("dir", languageCode === "ar" ? "rtl" : "ltr");
    window.location.reload();
  };

  const setCurrencyTrigger = (e) => {
    const currencyName = e.target.value;
    // dispatch(setCurrency(currencyName));
    closeMobileMenu();
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };
  useEffect(() => {
    if (bussinessData) {
      dispatch(setCurrency(bussinessData.businessCurrency));
    }
    // console.log(bussinessData);
  }, [bussinessData]);

  return (
    <div className="mobile-menu-middle pl-20 pr-20">
      <div className="lang-curr-style">
        <span className="title-currency mb-2">{t("txt_chooselanguage")} </span>
        <div className="language-selector">
          <select
            value={i18n.resolvedLanguage}
            onChange={changeLanguageTrigger}
          >
            <option value="en">English</option>
            <option value="ar">عربي</option>
          </select>
          <ArrowDownSvg />
        </div>
      </div>

      {/* <div className="lang-curr-style">
        <span className="title mb-2">Choose Currency</span>
        <select
          value={currency.currencyName}
          onChange={setCurrencyTrigger}
        >
          <option value={bussinessData.businessCurrency}>{bussinessData.businessCurrency}</option>
        </select>
      </div> */}
    </div>
  );
};

export default MobileLangCurrChange;
