import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useTranslationHook from "../../../hooks/useTranslationHook";
import { getDiscountPrice } from "../../../helpers/product";
import { deleteFromCart } from "../../../store/slices/cart-slice";
import { numberWithCommas } from "../../../shared/helpersMethod";

const MenuCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslationHook();
  const currency = useSelector((state) => state.currency);
  const { cartItems } = useSelector((state) => state.cart);
  let cartTotalPrice = 0;

  return (
    <div className="shopping-cart-content">
      {cartItems && cartItems.length > 0 ? (
        <Fragment>
          <ul>
            {cartItems.map((item) => {
              const discountedPrice = getDiscountPrice(
                item.price,
                item.discount
              );
              const finalProductPrice = (
                item.price * currency.currencyRate
              ).toFixed(2);
              const finalDiscountedPrice = (
                discountedPrice * currency.currencyRate
              ).toFixed(2);

              discountedPrice != null
                ? (cartTotalPrice += finalDiscountedPrice * item.quantity)
                : (cartTotalPrice += finalProductPrice * item.quantity);

              return (
                <li className="single-shopping-cart" key={item.cartItemId}>
                  <div className="shopping-cart-img">
                    <Link to={`/product/${item.slug}/${item.id}`}>
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + item.image[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={`/product/${item.slug}/${item.id}`}>
                        {" "}
                        {item.name}{" "}
                      </Link>
                    </h4>
                    <h6>
                      {t("txt_quantity")}: {item.quantity}
                    </h6>
                    <span>
                      {discountedPrice !== null
                        ? numberWithCommas(finalDiscountedPrice)
                        : numberWithCommas(finalProductPrice)}
                      <span style={{ marginLeft: 5, marginRight: 5 }}>
                        {currency.currencySymbol}
                      </span>
                    </span>

                    {item.selectedProductColor && item.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {item.selectedProductColor}</span>
                        <span>Size: {item.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="shopping-cart-delete">
                    <button
                      onClick={() =>
                        dispatch(
                          deleteFromCart({
                            cartItemId: item.cartItemId,
                            translation: t("txt_decreased_cart"),
                          })
                        )
                      }
                    >
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            {/* <h4> */}
            <span>{t("txt_total")}:</span>{" "}
            <span className="shop-total">
              <span>{numberWithCommas(cartTotalPrice.toFixed(2))}</span>{" "}
              <span>{currency.currencySymbol}</span>
            </span>
            {/* </h4> */}
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              {t("txtx_view_cart")}
            </Link>
            <Link
              className="default-btn"
              to={process.env.PUBLIC_URL + "/checkout"}
            >
              {t("txt_checkout")}
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">{t("txt_no_items")}</p>
      )}
    </div>
  );
};

export default MenuCart;
