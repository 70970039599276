// import cogoToast from 'cogo-toast';
import { ICATEGORY } from '../../shared/commonUtils';
const { createSlice } = require('@reduxjs/toolkit');
const compareSlice = createSlice({
    name: "homePageCategories",
    initialState: {
        homePageCategories: [] as ICATEGORY[]
    },
    reducers: {
        addHomePageCategories(state:any, action:any) {
            state.homePageCategories=action.payload;
            // cogoToast.success("Added To compare", {position: "bottom-left"});
        },
        deleteHomePageCategories(state:any, action:any){
            state.homePageCategories = state.homePageCategories.filter((item:any) => item.id !== action.payload);
            // cogoToast.error("Removed From Compare", {position: "bottom-left"});
        }
    },
});

export const { addHomePageCategories, deleteHomePageCategories } = compareSlice.actions;
export default compareSlice.reducer;
