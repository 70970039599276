import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { Footer, ISOCIALLINKS } from "../../shared/commonUtils";
import React, { useEffect, useState } from "react";
import {
  FooterFacebook,
  FooterInstagram,
  FooterPhone,
  FooterTiktok,
  PaymentApplePay,
  PaymentGooglePay,
  PaymentMaster,
  PaymentPayPal,
  PaymentVisa,
} from "../../assets/svgs/svgs";
import { useAppSelector } from "../../hooks/useReduxMethods";
interface IFooterOnePROPS {
  backgroundColorClass?: any;
  spaceTopClass?: any;
  spaceBottomClass?: any;
  spaceLeftClass?: any;
  spaceRightClass?: any;
  containerClass?: any;
  extraFooterClass?: any;
  sideMenu?: any;
  footerPadding?: boolean;
}
const FooterOne: React.FC<IFooterOnePROPS> = (props: IFooterOnePROPS) => {
  const {
    backgroundColorClass,
    spaceTopClass,
    spaceBottomClass,
    spaceLeftClass,
    spaceRightClass,
    containerClass,
    extraFooterClass,
    footerPadding,
    sideMenu,
  } = props;
  const { bussinessData } = useAppSelector((state) => state.bussinessData);
  const [footerData, setFooterData] = useState<Footer>(bussinessData?.footer);
  const [socialLinks, setSocialLinks] = useState<ISOCIALLINKS>(
    bussinessData?.socialLinks
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (bussinessData) {
      setFooterData(bussinessData.footer);
      setSocialLinks(bussinessData?.socialLinks);
    }
  }, [bussinessData]);
  return (
    <>
      <footer
        id="google_translate_element"
        className={clsx(
          "footer-area",
          backgroundColorClass,
          spaceTopClass,
          spaceBottomClass,
          extraFooterClass,
          spaceLeftClass,
          spaceRightClass
        )}
      >
        <div
          className={`${containerClass ? containerClass : "container"} pl-20 pr-20`}
        >
          <div className="row">
            {Object.keys(bussinessData).length > 0 && (
              <div
                className={`${
                  sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                }`}
              >
                {/* footer copyright */}
                <FooterCopyright
                  footerLogo={bussinessData?.site_settings?.siteLogo}
                  spaceBottomClass="mb-30"
                />
              </div>
            )}
            {Object.keys(bussinessData).length > 0 &&
              footerData &&
              null !== footerData &&
              Object.entries(footerData.pages).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`${
                      sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"
                    }`}
                  >
                    <div className="footer-widget mb-30 ml-30">
                      <div className="footer-title mb-10">
                        <span className="bold ">
                          {t(`${item[0].replace(" ", "_").toLowerCase()}`)}
                        </span>
                      </div>
                      <div className="footer-list">
                        <ul>
                          {item[1].map((itemChild, index) => {
                            return (
                              <li>
                                <Link
                                  to={
                                    process.env.PUBLIC_URL + `/${itemChild.id}`
                                  }
                                >
                                  {itemChild.title}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              })}
            <div
              className={`${
                sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
              }`}
            >
              <div
                className={`${
                  sideMenu
                    ? "footer-widget mb-30 ml-145"
                    : "footer-widget mb-30 ml-50"
                }`}
              >
                <div className="footer-title mb-10">
                  <span className="bold ">{t(`contact_us`)}</span>
                </div>
                <div
                  className="d-flex gap-3  "
                  onClick={() => {
                    window.open(`tel:${bussinessData?.businessPhone}`);
                  }}
                >
                  <div>
                    <FooterPhone />
                  </div>{" "}
                  <div className="mt-2">{bussinessData.businessPhone}</div>
                </div>
                {/* <div className=" ">{t(`contact_us`)}</div> */}
              </div>
            </div>

            <div
              className={`${
                sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"
              }`}
            >
              <div
                className={`${
                  sideMenu
                    ? "footer-widget mb-30 ml-145"
                    : "footer-widget mb-30 ml-50"
                }`}
              >
                <div className="footer-title mb-10">
                  <span className="bold">{t("follow_us")}</span>
                </div>
                <div className="footer-list d-flex">
                  {socialLinks?.fb && (
                    <a
                      className="footer-icon-bg"
                      href={socialLinks.fb}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FooterFacebook />
                    </a>
                  )}
                  {socialLinks?.tiktok && (
                    <a
                      className="footer-icon-bg"
                      href={socialLinks.tiktok}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FooterTiktok />
                    </a>
                  )}
                  {socialLinks?.insta && (
                    <a
                      className="footer-icon-bg"
                      href={socialLinks.insta}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FooterInstagram />
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`${
                sideMenu ? "col-xl-3 col-sm-8" : "col-lg-4 col-sm-6"
              }`}
            >
              {/* footer newsletter */}
              {/* <FooterNewsletter
              spaceBottomClass="mb-30"
              spaceLeftClass="ml-70"
              sideMenu={sideMenu}
            /> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="pl-20 pr-20 mb-20 mt-20">
        <span className="bold text-uppercase">
          {t("txt_available_payment")}
        </span>
        <div className="mt-10">
          <PaymentVisa className=" mr-5" />
          <PaymentApplePay className="ml-5 mr-5" />
          <PaymentGooglePay className="ml-5 mr-5" />
          <PaymentPayPal className="ml-5 mr-5" />
          <PaymentMaster className="ml-5 mr-5" />
        </div>
      </div>
      {footerPadding && (
        <div className="d-lg-none">
          {new Array(5).fill(0).map(() => (
            <br />
          ))}
        </div>
      )}
    </>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default FooterOne;
