import axios from 'axios';

// TODO Should be fetched form the env file
// const baseURL = 'https://dev.taiftec.com/sites/postsales.ncd.iq/api/';


// https://devsrvorderapi.dukany.io/api/
// export const baseURL ='https://devsrvorderapi.dukany.io/api/';
export const baseURL ='https://orderapi.dukany.io/api/'; //prod

export const instance = axios.create({
  baseURL,
  timeout: 100000,
});

instance.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export default instance;
