
import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinks";
import {ICATEGORY } from "../../shared/commonUtils";
import { useDispatch} from "react-redux";
import {  httpPostWithAuth } from "../../axios/axiosUtils";
import { getBussinessData, getUserPrefersLang } from "../../shared/helpersMethod";
import { addHomePageCategories } from "../../store/slices/categories-nav-menu";
// import cogoToast from "cogo-toast";
// import { getBussinessData } from "../../shared/helpersMethod";
const useGetHomeCategory = () => {
  const dispath = useDispatch();
  const [loading, setLoading] = useState(false);
  const [homePageCategories,setHomePageCategories] = useState<ICATEGORY[]>([]);
  const userLanguage = getUserPrefersLang();
  const queryParamLang = `?lang=${userLanguage}`;
  
//   const bussinessToken = getBussinessData();

  const getHomePageCategory = async (test:string
    // theme:string,
    // template:string,
    // page:string,
    // element:string

  ) => {
  const bussinessData = getBussinessData();
   console.log(test)
    setLoading(true);
    try {
      const res = await httpPostWithAuth(URL_CONSTANTS.get('URL_Get_Categories')!  + `${userLanguage !== null ? queryParamLang :"" }`,{
        // theme,
        // template,
        // page,
        // element

      },bussinessData.accessToken)
      setLoading(false);
      if (res.type === "error"){
        // cogoToast.error(res.message)
        return;
      }
      if(res.type !== 'error' && res.data)
      setHomePageCategories(res)
      dispath(addHomePageCategories(res));
     
      
    } catch (error: any) {
      // setBussinessData(getBussinessData())
      console.log(error.message);
      if(error){

      }
      setLoading(false);
    }
  };
  
 
  
  return {
    homePageCategories,
    getHomePageCategory,
    loading,
  };
};
export default useGetHomeCategory;
