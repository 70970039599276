import React from "react";
import ReactLoading from "react-loading";
interface LoaderProps {
  height: string | number;
  width: string | number;
  colorProp?: string;
  stylesProp?: React.CSSProperties;
}
const ButtonLoader: React.FC<LoaderProps> = (props: LoaderProps) => {
  const { colorProp, height, width, stylesProp } = props;
  return (
    <div
      style={{
        ...stylesProp,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ReactLoading
        type={"spinningBubbles"}
        color={"var(--pre-loader-color)"}
        height={height}
        width={width}
      />
    </div>
  );
};

export default ButtonLoader;
