import React from "react";
import { ProductCartItem } from "../../../shared/commonUtils";
import { ReactComponent as CartCross } from "../../../assets/svgs/CartCross.svg";
import { numberWithCommas } from "../../../shared/helpersMethod";
import { useAppSelector } from "../../../hooks/useReduxMethods";
import ProductQuantity from "../../../components/product-qunatity/ProductQunatity";

interface IMOBIELCARTITEMS {
  cartItem: ProductCartItem;
  onPressIncrease: (cartItem: ProductCartItem) => void;
  onPressDecrease: (cartItem: ProductCartItem) => void;
  onPressDelete: (cartItem: ProductCartItem) => void;
}
const MobileCartItems: React.FC<IMOBIELCARTITEMS> = (
  props: IMOBIELCARTITEMS
) => {
  const { cartItem, onPressIncrease, onPressDecrease, onPressDelete } = props;
  const currency = useAppSelector((state) => state.currency);
  return (
    <div className="cart-container" key={cartItem.id}>
      <div className="cart-item">
        <div className="delete-icon" onClick={() => onPressDelete(cartItem)}>
          <CartCross />
          {/* <DeleteIcon /> */}
        </div>
        <div className="item-image">
          <img src={cartItem.image[0]} alt="Product" />
        </div>
        <div className="item-details">
          <h3>{cartItem.name}</h3>
          {/* TODO:: we will add in future */}
          {/* <h4>HSU19K-PZFB5BN-INV</h4> */}
          {cartItem.selectedLableValue &&
          Object.keys(cartItem.selectedLableValue).length > 0 ? (
            <div className="cart-item-variation">
              <span>
                {cartItem.selectedLableValue.firstLabel.key}:{" "}
                {cartItem.selectedLableValue.firstLabel.value}
              </span>
              {cartItem.selectedLableValue.secondLabel && (
                <span>
                  {cartItem.selectedLableValue.secondLabel.key}:{" "}
                  {cartItem.selectedLableValue.secondLabel.value}
                </span>
              )}
            </div>
          ) : (
            ""
          )}
          <p className="price">
            <span>
              {" "}
              {numberWithCommas(cartItem.price) + " " + currency.currencySymbol}
            </span>
          </p>

          {/* TODO:: we will add in future */}
          {/* <p className="delivery">Delivery by Tomorrow</p> */}
        </div>
      </div>
      <div className="cart-item-bottom d-flex flex-direction-row  align-items-center ">
        <ProductQuantity
          fromCart={true}
          quantity={cartItem.quantity}
          onPressDecrease={(cartItem) => onPressDecrease(cartItem)}
          onPressIncrease={(cartItem) => onPressIncrease(cartItem)}
          itemObject={cartItem}
        />
        <p className="price">
          <span>
            {" "}
            {numberWithCommas(cartItem.price * cartItem.quantity) +
              " " +
              currency.currencySymbol}
          </span>
          {/* <span className="mrp">MRP ₹58,990</span> Our Price ₹49,990 */}
        </p>
      </div>
    </div>
  );
};

export default MobileCartItems;
