import axios from "axios";
export const AQSATI_CARD = "AQSATI";
export interface ICUSTOMERINFO {
  Address: any;
  email: string | null;
  id: number;
  isNew: "0" | "1";
  mobile_number: string;
  name: string;
}
export interface IInstallmentObjectPL {
  isInstallmentValid: "Yes" | "No";
  installment: {
    identity: string;
    otp: string;
    numberOfMonths: number;
    operationId: string;
    amount: string | number;
    trnid: string;
    notes: string;
  };
}
export interface IAqsatiCustomer {
  customerName: string;
  phoneNumber: string;
  installmentOptions: InstallmentOptions;
}

export interface InstallmentOptions {
  minMonth: number;
  maxMonth: number;
  netPayment: number;
  dedections: number;
  countOfMonth: number;
}
export interface ISEARCHEDDATA {
  name: string;
  data_id: string;
  source: "products" | "category";
  item_discounts: any;
  product_discounts: any;
}
export interface I_EXTERNAL_PAGES {
  slug: string;
  title: string;
  short_description: string;
  content: I_EXTERNAL_CONTENT;
  image: string;
}
export interface I_EXTERNAL_CONTENT {
  plan_text: string;
  with_html: string;
}
export interface IProxyIp {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  IPv4: string;
  query: string;
  region: string;
  regionName: string;
  status: string;
  timezone: string;
  zip: string;
}

export interface IPLACE_ORDER_REQ_loyalty {
  cash_back: string | number;
  redeemable_amount: string | number;
  is_loyalty_used: "Yes" | "No";
  loyalty_id: string;
  points_to_be_used: string | number;
}
export interface IPLACE_ORDER_REQ {
  is_loyalty: "Yes" | "No";
  latlng: string;
  address_id: number;
  address_value: string;
  discounted_amt_delivery_fee: string;
  is_delivery_discount: string;
  delivery_discount_value: number;
  delivery_discount_type: string;
  userIpInfo: IProxyIp;
  billingGateway: string;
  payment_mode: string;
  area: string | null;
  cart: any;
  total_price: string;
  mobile_number: string;
  customer_name: string;
  customer_id: string;
  order_instructions: string;
  delivery_fee: string;
  time: string;
  selected_area: string;
  ip_address: string;
  order_type: string;
  campaign_date: string;
  status: string;
  outlet_id: string;
  loyalty?: IPLACE_ORDER_REQ_loyalty | null;
  recipient_id: string;
  exchange_rate: string;
  campaign_type: string;
  isDev: number;
  is_discount: string;
  isCrDev: number;
  is_for_whole_order: string;
  discount_id?: string;
  discount_type?: string;
  discount_amount?: string;
  discounted_amount?: string;
  greeting_message?: string;
}

export interface ReceiverData {
  id: number;
  messages: messages[];
  recipient_name: string;
  recipient_phone: string;
}
interface messages {
  created_at: string;
  customer_id: number;
  deleted_at: string;
  greeting_message: string;
  id: number;
  receiver_id: number;
  set_default: number;
  updated_at: string;
}
export interface AddressInterface {
  address: string;
  area: string;
  delivery_fee: number;
  google_selected_address: string;
  id: number;
  instrunctions: string;
  is_set: number;
  label: string;
  selected_lat_lng: string;
}
export interface OrderResponse {
  message: string;
  order_id: number;
  order_ref: number;
  payment_link: string;
  tracking_id: string;
}

export interface IHomePageSlider {
  id: string;
  title: string;
  subtitle: string;
  image: string;
  url: string;
}

export interface IBRANDLOGO {
  id: string;
  image: string;
}

export interface ICATEGORY {
  id: string;
  image: string;
  subtitle: string;
  title: string;
  link: string;
}

export interface IALLPRODUCTS {
  id: number;
  sku: string;
  name: string;
  price: number;
  new: boolean;
  rating: number;
  saleCount: number;
  category: string[];
  tag: string[];
  stock: number;
  image: string[];
  shortDescription: string;
  fullDescription: string;
}

export interface ICATEGORY {
  id: string;
  unique_id: string;
  name: string;
  childern: ICATEGORYCHILD[];
}

export interface ICATEGORYCHILD {
  id: number;
  unique_id: string;
  name: string;
  parent_id: number;
  childern: ICATEGORYCHILD[];
}
// //////
export interface IRESAllData {
  type: string;
  data: IHOMEPAGEData;
  message: string;
  token?: string;
}

export interface IHOMEPAGEData {
  businessName: BusinessName;
  businessAddress: string;
  businessWhatsapp: any;
  businessCountry: {
    country_currency: string;
    country_long_name: string;
    country_name: string;
    country_short_name: string;
    country_code: number;
    country_code_in_letters: string;
    country_coordinates: {
      latitude: string;
      longitude: string;
    };
  };
  businessPhone: any;
  businessMainImage: any;
  businessID: number;
  businessCurrency: string;
  defaultLang: string;
  businessMetas: IBusinessMetas;
  businessOutlets: OutLetsObj[];
  businessThemeLogo: string;
  businessUniqueID: string;
  header_banner: IHEADERBANNER[];
  currencyList: string[];
  languageList: string[];
  selectedTheme: SelectedTheme;
  homepage_configurations: HomepageConfigurations;
  footer: Footer;
  site_settings: I_SITE_SETTINGS;
  socialLinks: ISOCIALLINKS;
}
export interface IBusinessMetas {
  BILLING_GATEWAY: IBILLINGGATEWAY[];
  BUSSINESS_CCY: string;
  BUSSINESS_RECEIVER_FLAG: "Yes" | "No";
  BUSSINESS_TIMESLOTS_FLAG: "Yes" | "No";
  CONTACT_US: string;
  DEFAULT_DELIVERY_FEE: string;
  DISABLED_LOCATION_SERVICES: "Yes" | "No";
  DISABLE_GOOGLE_MAPS: "Yes" | "No";
  ENABLE_CALENDAR: "Yes" | "No";
  IMGIX_SOURCE: string;
  PRIVACY_POLICY: string;
  REFUND_POLICY: string;
}
export interface IBILLINGGATEWAY {
  icon: string;
  id: number;
  image: string;
  value: string;
}
export interface I_SITE_SETTINGS {
  siteTitle: string;
  siteLogo: string;
  siteFavicon: string;
  mouseOverColor: string;
  mouseSelectionColor: string;
  buttonColor: string;
  sliderBackgroundColor: string;
  sliderTextColor: string;
  ProductBackgroundColor: string;
  siteFeaturedProductPrimaryBackgroundColor: string;
  siteFeaturedProductSecondaryBackgroundColor: string;
  productTextColor: string;
  siteHeaderDynamicText: string[];
  siteDescription: string;
}
export interface IHEADERBANNER {
  business_id: number;
  created_at: string;
  deleted_at: string;
  description: string;
  icon: string;
  id: string;
  image: string | null;
  type: string;
  updated_at: string;
}
export interface ISOCIALLINKS {
  fb: string;
  geoLocation: string;
  insta: string;
  soical_site: string;
  tiktok: string;
  whatsapp: string;
}
export interface BusinessName {
  en: string;
  ar: string;
}

export interface SelectedTheme {
  slug: string;
  name: string;
  short_name: string;
}

export interface HomepageConfigurations {
  welcome_info: string;
  homepage_slider: HomepageSlider;
  homepage_categories: HomepageCategory[];
  display_sections: {
    display_brands: "Yes" | "No";
    display_featured_categories: "Yes" | "No";
    display_featured_products: "Yes" | "No";
    display_home_slider: "Yes" | "No";
    display_info_section: "Yes" | "No";
    display_product_carousal: "Yes" | "No";
  };
  homepage_carousel_slider: HomepageCarouselSlider[];
  homepage_featured_product: HomepageFeaturedProduct[];
  homepage_brands: HomepageBrand[];
  pg_home_featured_div_color: {
    colorL: string;
    colorR: string;
  };
}

export interface HomepageSlider {
  title: string;
  bgColor: string | null;
  display_at: string;
  slides: Slide[];
}

export interface Slide {
  id: number;
  title: string;
  subtitle: string;
  button_text: string;
  image: string;
  url: string;
}

export interface HomepageCategory {
  id: string;
  name: string;
  image: string;
  slug: string;
}

export interface HomepageCarouselSlider {
  title: string;
  products: Product[];
}

export interface Product {
  id: string;
  sku: string;
  slug: string;
  isAvailable: string;
  name: string;
  price: string;
  affiliateLink: string | null;
  new: boolean;
  rating: number;
  brand: IBrand | null;
  saleCount: number;
  discount: any | null;
  category: string[];
  categoryWithSlugID: IcategoryWithSlugID;
  tag: string[];
  specifications: Specification[];
  theme_desc: string;
  stock: number;
  image: string;
  shortDescription?: string;
  variations: any;
  fullDescription?: string;
  inventory_un_available_reason: IINVENTORYREASON;
}
interface IINVENTORYREASON {
  [key: string]: string;
}
export interface IBrand {
  brandName: string;
  image: string;
  slug: string;
}
export interface IcategoryWithSlugID {
  [key: string]: string;
}
export interface Specification {
  title: string;
  children: SpecificationChildren[];
}

export interface SpecificationChildren {
  key: string;
  value: string;
}

export interface Name {
  en: string;
  ar: string;
}

export interface Price {
  plan: string;
  formatted: string;
}

export interface HomepageFeaturedProduct {
  id: string;
  name: Name2;
  price: Price2;
  image: string;
}

export interface Name2 {
  en: string;
  ar: string;
}

export interface Price2 {
  plan: string;
  formatted: string;
}
export interface Footer {
  pages: Pages;
}

export interface Pages {
  [key: string]: IFooterCoulmnInfo[];
}

export interface IFooterCoulmnInfo {
  id: string;
  title: string;
  short_description: string;
}

export interface HomepageBrand {
  id: number;
  name: string;
  slug: string;
  image: string;
}
///////////////////////////////  // \\
export const GOOGLE_MAPS_API_KEY = "GOOGLE_MAPS_API_KEY_";
// export const ENVIRONMENT = "DEV"; // comment in prodiction
export const ENVIRONMENT = "PROD"; // uncomment in prodiction
//<string, string>
export const GOOGLE_MAPS_KEYS = new Map([
  [
    GOOGLE_MAPS_API_KEY + "DEV",
    "AIzaSyBFh6fzq8G7dgWLfz8kccvTlmPCSI_uWXQ",
    // "AIzaSyBFh6fzq8G7dgWLfz8kccvTlmPCSI_uWXQ"
  ],
  [GOOGLE_MAPS_API_KEY + "PROD", "AIzaSyAODq7uToLl3uj02dzoHA9YSVSsAKW61to"],
]);

export interface OtpRespone {
  data: {
    msisdn: string;
    req_id: string;
    sms_id: number;
    status: string;
  };
  message: string;
  type: string;
}
export interface OtpResData {
  data: {
    email: string;
    id: number;
    mobile_number: string;
    name: string;
    isNew: string;
  };
  message: string;
  type: string;
}
export interface IOTPCUSTRES {
  email: string;
  id: number;
  mobile_number: string;
  name: string;
  isNew: string;
}

export const isNotValidNumber = (
  phoneNumber: string,
  countryCode: string,
  countryCodeString: string
) => {
  /** */
  var isNotValid = false;

  // console.log(phoneNumber,countryCode);
  // finding the index from all country array
  const findIndex = ALL_COUNTRIES_ARRAY.findIndex(
    (item) => item.code.toLocaleLowerCase() == countryCodeString.toLowerCase()
  );

  // country  number length with
  var actulaPhoneLength = ALL_COUNTRIES_ARRAY[findIndex].phoneLength!;

  // console.log(actulaPhoneLength,"...actulaPhoneLength");
  var startsWithZero = phoneNumber.startsWith(countryCode + "0");
  // ignoring zero at the start
  // 474146681544
  var validatePhone = phoneNumber;
  // console.log('....s',startsWithZero,'...phonenumber...',phoneNumber)
  if (startsWithZero) {
    validatePhone = phoneNumber.replace(
      new RegExp(countryCode + "0", "i"),
      countryCode
    );
  }
  // console.log(validatePhone,"...validatePhone");
  // ignoring country code
  var userPhoneLength = validatePhone.length - countryCode.length;
  // console.log(userPhoneLength,"...userPhoneLength");
  if (validatePhone.length === 0 || userPhoneLength !== actulaPhoneLength) {
    // console.log("...incondition",);
    // setShowError(true);
    isNotValid = true;
  }
  return { isNotValid, validatePhone };
};
export interface OutLetsObj {
  id: string;
  name: Name;
  timezone: string;
  email: string[];
  address?: string;
  whatsapp: string;
  phone_number: string;
  delivery_area: DeliveryArea;
  outlet_delivery: number;
  outlet_pickup: number;
  outlet_delivery_payment_methods: string;
  outlet_pickup_payment_methods: string;
  business_hours: BusinessHours;
  outlet_delivery_slot_length: number;
  delivery_time: string;
  preparation_delivery_type: string;
  closed_days: any;
  delivery_days: number;
}
export interface Name {
  en: string;
  ar: string;
}

export interface DeliveryArea {
  [key: string]: I_DELIERY_AREA;
}
export interface I_DELIERY_AREA {
  outlet_id: number;
  minimum_basket_price: number;
  area_id: number;
  outlet_name: string;
  outlet_name_translation: OutletNameTranslation;
  area_name: string;
  lat_lng: string;
  delivery_fee: number;
}
export interface OutletNameTranslation {
  en: string;
  ar: string;
}
export interface BusinessHours {
  pickup: Pickup[];
  delivery: Delivery[];
}
export interface Pickup {
  [key: string]: I_DAYS_OBJ;
}
export interface Delivery {
  [key: string]: I_DAYS_OBJ;
}
export interface I_DAYS_OBJ {
  start_time: string;
  end_time: string;
  status: string;
  format_start_time: string;
  format_end_time: string;
  start_time_delivery_preparation_time: string;
  end_time_delivery_preparation_time: string;
  start_time_delivery_preparation_time_s: string;
  end_time_delivery_preparation_time_s: string;
}

export interface SelectedOulet extends OutLetsObj {
  delivery_area: I_DELIERY_AREA;
  selectedLatLng: string;
  areaSlug: string;
}
export const getUserIpInfo = () => {
  var getIpInfo = localStorage.getItem("userIpInfo");
  var userIpInfo: IProxyIp = {} as IProxyIp;
  if (getIpInfo != "undefined" && null !== getIpInfo) {
    userIpInfo = JSON.parse(getIpInfo);
    return userIpInfo;
  }
  var dummyIpInfo = {
    status: "success",
    country: "Pakistan",
    countryCode: "PK",
    region: "PB",
    IPv4: "",
    regionName: "Punjab",
    city: "Little Attock",
    zip: "43560",

    lat: 33.8945,
    lon: 72.2363,
    timezone: "Asia/Karachi",
    isp: "CMPak Limited",
    org: "",
    as: "AS138423 CMPak Limited",
    query: "121.91.61.114",
  };
  return dummyIpInfo;
};
export const getProxyData = async () => {
  //@ts-ignore
  if (window.userWifiIp !== "{userWifiIp}") {
    const resMeemApi = await axios.get(
      //@ts-ignore
      `https://orderapi.dukany.io/api/rjs/proxyIpCall?ip=${window.userWifiIp}`
    );
    return resMeemApi.data;
  }
};
export const getCountryNameByCode = (code: string) => {
  const country = ALL_COUNTRIES_ARRAY.find((country) => country.code === code);
  return country ? country.label : null;
};

export const ALL_COUNTRIES_ARRAY = [
  { code: "AD", label: "Andorra", phone: "376", phoneLength: 6 },
  { code: "AE", label: "United Arab Emirates", phone: "971", phoneLength: 9 },
  { code: "AF", label: "Afghanistan", phone: "93", phoneLength: 9 },
  { code: "AG", label: "Antigua and Barbuda", phone: "1-268", phoneLength: 10 },
  { code: "AI", label: "Anguilla", phone: "1-264", phoneLength: 10 },
  { code: "AL", label: "Albania", phone: "355", phoneLength: 9 },
  { code: "AM", label: "Armenia", phone: "374", phoneLength: 6 },
  { code: "AO", label: "Angola", phone: "244", phoneLength: 9 },
  { code: "AQ", label: "Antarctica", phone: "672", phoneLength: 6 },
  { code: "AR", label: "Argentina", phone: "54", phoneLength: 8 },
  { code: "AS", label: "American Samoa", phone: "1-684", phoneLength: 10 },
  { code: "AT", label: "Austria", phone: "43", phoneLength: 11 },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
    phoneLength: 9,
  },
  { code: "AW", label: "Aruba", phone: "297", phoneLength: 7 },
  { code: "AX", label: "Alland Islands", phone: "358", phoneLength: 10 },
  { code: "AZ", label: "Azerbaijan", phone: "994", phoneLength: 9 },
  { code: "BA", label: "Bosnia and Herzegovina", phone: "387", phoneLength: 8 },
  { code: "BB", label: "Barbados", phone: "1-246", phoneLength: 10 },
  { code: "BD", label: "Bangladesh", phone: "880", phoneLength: 10 },
  { code: "BE", label: "Belgium", phone: "32", phoneLength: 9 },
  { code: "BF", label: "Burkina Faso", phone: "226", phoneLength: 8 },
  { code: "BG", label: "Bulgaria", phone: "359", phoneLength: 9 },
  { code: "BH", label: "Bahrain", phone: "973", phoneLength: 8 },
  { code: "BI", label: "Burundi", phone: "257", phoneLength: 8 },
  { code: "BJ", label: "Benin", phone: "229", phoneLength: 8 },
  { code: "BL", label: "Saint Barthelemy", phone: "590", phoneLength: 9 },
  { code: "BM", label: "Bermuda", phone: "1-441", phoneLength: 10 },
  { code: "BN", label: "Brunei Darussalam", phone: "673", phoneLength: 7 },
  { code: "BO", label: "Bolivia", phone: "591", phoneLength: 9 },
  { code: "BR", label: "Brazil", phone: "55", phoneLength: 11 },
  { code: "BS", label: "Bahamas", phone: "1-242", phoneLength: 10 },
  { code: "BT", label: "Bhutan", phone: "975", phoneLength: 7 },
  { code: "BV", label: "Bouvet Island", phone: "47", phoneLength: 10 },
  { code: "BW", label: "Botswana", phone: "267", phoneLength: 7 },
  { code: "BY", label: "Belarus", phone: "375", phoneLength: 9 },
  { code: "BZ", label: "Belize", phone: "501", phoneLength: 7 },
  { code: "CA", label: "Canada", phone: "1", suggested: true, phoneLength: 10 },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    phoneLength: 10,
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    phoneLength: 7,
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    phoneLength: 8,
  },
  { code: "CG", label: "Congo, Republic of the", phone: "242", phoneLength: 9 },
  { code: "CH", label: "Switzerland", phone: "41", phoneLength: 9 },
  { code: "CI", label: "Cote d'Ivoire", phone: "225", phoneLength: 8 },
  { code: "CK", label: "Cook Islands", phone: "682", phoneLength: 5 },
  { code: "CL", label: "Chile", phone: "56", phoneLength: 9 },
  { code: "CM", label: "Cameroon", phone: "237", phoneLength: 9 },
  { code: "CN", label: "China", phone: "86", phoneLength: 11 },
  { code: "CO", label: "Colombia", phone: "57", phoneLength: 10 },
  { code: "CR", label: "Costa Rica", phone: "506", phoneLength: 8 },
  { code: "CU", label: "Cuba", phone: "53", phoneLength: 8 },
  { code: "CV", label: "Cape Verde", phone: "238", phoneLength: 7 },
  { code: "CW", label: "Curacao", phone: "599", phoneLength: 7 },
  { code: "CX", label: "Christmas Island", phone: "61", phoneLength: 9 },
  { code: "CY", label: "Cyprus", phone: "357", phoneLength: 8 },
  { code: "CZ", label: "Czech Republic", phone: "420", phoneLength: 9 },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
    phoneLength: 10,
  },
  { code: "DJ", label: "Djibouti", phone: "253", phoneLength: 10 },
  { code: "DK", label: "Denmark", phone: "45", phoneLength: 8 },
  { code: "DM", label: "Dominica", phone: "1-767", phoneLength: 10 },
  { code: "DO", label: "Dominican Republic", phone: "1-809", phoneLength: 10 },
  { code: "DZ", label: "Algeria", phone: "213", phoneLength: 9 },
  { code: "EC", label: "Ecuador", phone: "593", phoneLength: 9 },
  { code: "EE", label: "Estonia", phone: "372", phoneLength: 8 },
  { code: "EG", label: "Egypt", phone: "20", phoneLength: 10 },
  { code: "EH", label: "Western Sahara", phone: "212", phoneLength: 9 },
  { code: "ER", label: "Eritrea", phone: "291", phoneLength: 7 },
  { code: "ES", label: "Spain", phone: "34", phoneLength: 9 },
  { code: "ET", label: "Ethiopia", phone: "251", phoneLength: 9 },
  { code: "FI", label: "Finland", phone: "358", phoneLength: 11 },
  { code: "FJ", label: "Fiji", phone: "679", phoneLength: 7 },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    phoneLength: 5,
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    phoneLength: 7,
  },
  { code: "FO", label: "Faroe Islands", phone: "298", phoneLength: 5 },
  { code: "FR", label: "France", phone: "33", suggested: true, phoneLength: 9 },
  { code: "GA", label: "Gabon", phone: "241", phoneLength: 7 },
  { code: "GB", label: "United Kingdom", phone: "44", phoneLength: 10 },
  { code: "GD", label: "Grenada", phone: "1-473", phoneLength: 10 },
  { code: "GE", label: "Georgia", phone: "995", phoneLength: 9 },
  { code: "GF", label: "French Guiana", phone: "594", phoneLength: 9 },
  { code: "GG", label: "Guernsey", phone: "44", phoneLength: 10 },
  { code: "GH", label: "Ghana", phone: "233", phoneLength: 9 },
  { code: "GI", label: "Gibraltar", phone: "350", phoneLength: 8 },
  { code: "GL", label: "Greenland", phone: "299", phoneLength: 6 },
  { code: "GM", label: "Gambia", phone: "220", phoneLength: 7 },
  { code: "GN", label: "Guinea", phone: "224", phoneLength: 9 },
  { code: "GP", label: "Guadeloupe", phone: "590", phoneLength: 9 },
  { code: "GQ", label: "Equatorial Guinea", phone: "240", phoneLength: 9 },
  { code: "GR", label: "Greece", phone: "30", phoneLength: 10 },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    phoneLength: 5,
  },
  { code: "GT", label: "Guatemala", phone: "502", phoneLength: 8 },
  { code: "GU", label: "Guam", phone: "1-671", phoneLength: 10 },
  { code: "GW", label: "Guinea-Bissau", phone: "245", phoneLength: 9 },
  { code: "GY", label: "Guyana", phone: "592", phoneLength: 7 },
  { code: "HK", label: "Hong Kong", phone: "852", phoneLength: 8 },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    phoneLength: 10,
  },
  { code: "HN", label: "Honduras", phone: "504", phoneLength: 8 },
  { code: "HR", label: "Croatia", phone: "385", phoneLength: 9 },
  { code: "HT", label: "Haiti", phone: "509", phoneLength: 8 },
  { code: "HU", label: "Hungary", phone: "36", phoneLength: 9 },
  { code: "ID", label: "Indonesia", phone: "62", phoneLength: 11 },
  { code: "IE", label: "Ireland", phone: "353", phoneLength: 9 },
  { code: "IL", label: "Israel", phone: "972", phoneLength: 9 },
  { code: "IM", label: "Isle of Man", phone: "44", phoneLength: 10 },
  { code: "IN", label: "India", phone: "91", phoneLength: 10 },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    phoneLength: 7,
  },
  { code: "IQ", label: "Iraq", phone: "964", phoneLength: 10 },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
    phoneLength: 11,
  },
  { code: "IS", label: "Iceland", phone: "354", phoneLength: 7 },
  { code: "IT", label: "Italy", phone: "39", phoneLength: 10 },
  { code: "JE", label: "Jersey", phone: "44", phoneLength: 10 },
  { code: "JM", label: "Jamaica", phone: "1-876", phoneLength: 10 },
  { code: "JO", label: "Jordan", phone: "962", phoneLength: 9 },
  { code: "JP", label: "Japan", phone: "81", suggested: true },
  { code: "KE", label: "Kenya", phone: "254", phoneLength: 10 },
  { code: "KG", label: "Kyrgyzstan", phone: "996", phoneLength: 9 },
  { code: "KH", label: "Cambodia", phone: "855", phoneLength: 9 },
  { code: "KI", label: "Kiribati", phone: "686", phoneLength: 8 },
  { code: "KM", label: "Comoros", phone: "269", phoneLength: 7 },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    phoneLength: 10,
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    phoneLength: 13,
  },
  { code: "KR", label: "Korea, Republic of", phone: "82", phoneLength: 9 },
  { code: "KW", label: "Kuwait", phone: "965", phoneLength: 8 },
  { code: "KY", label: "Cayman Islands", phone: "1-345", phoneLength: 7 },
  { code: "KZ", label: "Kazakhstan", phone: "7", phoneLength: 10 },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
    phoneLength: 9,
  },
  { code: "LB", label: "Lebanon", phone: "961", phoneLength: 8 },
  { code: "LC", label: "Saint Lucia", phone: "1-758", phoneLength: 7 },
  { code: "LI", label: "Liechtenstein", phone: "423", phoneLength: 7 },
  { code: "LK", label: "Sri Lanka", phone: "94", phoneLength: 7 },
  { code: "LR", label: "Liberia", phone: "231", phoneLength: 9 },
  { code: "LS", label: "Lesotho", phone: "266", phoneLength: 8 },
  { code: "LT", label: "Lithuania", phone: "370", phoneLength: 8 },
  { code: "LU", label: "Luxembourg", phone: "352", phoneLength: 9 },
  { code: "LV", label: "Latvia", phone: "371", phoneLength: 8 },
  { code: "LY", label: "Libya", phone: "218", phoneLength: 10 },
  { code: "MA", label: "Morocco", phone: "212", phoneLength: 9 },
  { code: "MC", label: "Monaco", phone: "377", phoneLength: 8 },
  { code: "MD", label: "Moldova, Republic of", phone: "373", phoneLength: 8 },
  { code: "ME", label: "Montenegro", phone: "382", phoneLength: 8 },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
    phoneLength: 6,
  },
  { code: "MG", label: "Madagascar", phone: "261", phoneLength: 7 },
  { code: "MH", label: "Marshall Islands", phone: "692", phoneLength: 7 },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
    phoneLength: 8,
  },
  { code: "ML", label: "Mali", phone: "223", phoneLength: 8 },
  { code: "MM", label: "Myanmar", phone: "95", phoneLength: 10 },
  { code: "MN", label: "Mongolia", phone: "976", phoneLength: 8 },
  { code: "MO", label: "Macao", phone: "853", phoneLength: 8 },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
    phoneLength: 7,
  },
  { code: "MQ", label: "Martinique", phone: "596", phoneLength: 9 },
  { code: "MR", label: "Mauritania", phone: "222", phoneLength: 8 },
  { code: "MS", label: "Montserrat", phone: "1-664", phoneLength: 10 },
  { code: "MT", label: "Malta", phone: "356", phoneLength: 8 },
  { code: "MU", label: "Mauritius", phone: "230", phoneLength: 8 },
  { code: "MV", label: "Maldives", phone: "960", phoneLength: 7 },
  { code: "MW", label: "Malawi", phone: "265", phoneLength: 9 },
  { code: "MX", label: "Mexico", phone: "52", phoneLength: 10 },
  { code: "MY", label: "Malaysia", phone: "60", phoneLength: 7 },
  { code: "MZ", label: "Mozambique", phone: "258", phoneLength: 12 },
  { code: "NA", label: "Namibia", phone: "264", phoneLength: 7 },
  { code: "NC", label: "New Caledonia", phone: "687", phoneLength: 6 },
  { code: "NE", label: "Niger", phone: "227", phoneLength: 8 },
  { code: "NF", label: "Norfolk Island", phone: "672", phoneLength: 6 },
  { code: "NG", label: "Nigeria", phone: "234", phoneLength: 8 },
  { code: "NI", label: "Nicaragua", phone: "505", phoneLength: 8 },
  { code: "NL", label: "Netherlands", phone: "31", phoneLength: 9 },
  { code: "NO", label: "Norway", phone: "47", phoneLength: 8 },
  { code: "NP", label: "Nepal", phone: "977", phoneLength: 10 },
  { code: "NR", label: "Nauru", phone: "674", phoneLength: 7 },
  { code: "NU", label: "Niue", phone: "683", phoneLength: 4 },
  { code: "NZ", label: "New Zealand", phone: "64", phoneLength: 9 },
  { code: "OM", label: "Oman", phone: "968", phoneLength: 8 },
  { code: "PA", label: "Panama", phone: "507", phoneLength: 8 },
  { code: "PE", label: "Peru", phone: "51", phoneLength: 9 },
  { code: "PF", label: "French Polynesia", phone: "689", phoneLength: 8 },
  { code: "PG", label: "Papua New Guinea", phone: "675", phoneLength: 8 },
  { code: "PH", label: "Philippines", phone: "63", phoneLength: 10 },
  { code: "PK", label: "Pakistan", phone: "92", phoneLength: 10 },
  { code: "PL", label: "Poland", phone: "48", phoneLength: 9 },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    phoneLength: 6,
  },
  { code: "PN", label: "Pitcairn", phone: "870", phoneLength: 9 },
  { code: "PR", label: "Puerto Rico", phone: "1", phoneLength: 10 },
  { code: "PS", label: "Palestine, State of", phone: "970", phoneLength: 9 },
  { code: "PT", label: "Portugal", phone: "351", phoneLength: 9 },
  { code: "PW", label: "Palau", phone: "680", phoneLength: 7 },
  { code: "PY", label: "Paraguay", phone: "595", phoneLength: 9 },
  { code: "QA", label: "Qatar", phone: "974", phoneLength: 8 },
  { code: "RE", label: "Reunion", phone: "262", phoneLength: 10 },
  { code: "RO", label: "Romania", phone: "40", phoneLength: 10 },
  { code: "RS", label: "Serbia", phone: "381", phoneLength: 9 },
  { code: "RU", label: "Russian Federation", phone: "7", phoneLength: 10 },
  { code: "RW", label: "Rwanda", phone: "250", phoneLength: 9 },
  { code: "SA", label: "Saudi Arabia", phone: "966", phoneLength: 9 },
  { code: "SB", label: "Solomon Islands", phone: "677", phoneLength: 7 },
  { code: "SC", label: "Seychelles", phone: "248", phoneLength: 7 },
  { code: "SD", label: "Sudan", phone: "249", phoneLength: 7 },
  { code: "SE", label: "Sweden", phone: "46", phoneLength: 7 },
  { code: "SG", label: "Singapore", phone: "65", phoneLength: 8 },
  { code: "SH", label: "Saint Helena", phone: "290", phoneLength: 4 },
  { code: "SI", label: "Slovenia", phone: "386", phoneLength: 9 },
  { code: "SJ", label: "Svalbard and Jan Mayen", phone: "47", phoneLength: 8 },
  { code: "SK", label: "Slovakia", phone: "421", phoneLength: 9 },
  { code: "SL", label: "Sierra Leone", phone: "232", phoneLength: 8 },
  { code: "SM", label: "San Marino", phone: "378", phoneLength: 10 },
  { code: "SN", label: "Senegal", phone: "221", phoneLength: 9 },
  { code: "SO", label: "Somalia", phone: "252", phoneLength: 9 },
  { code: "SR", label: "Suriname", phone: "597", phoneLength: 7 },
  { code: "SS", label: "South Sudan", phone: "211", phoneLength: 7 },
  { code: "ST", label: "Sao Tome and Principe", phone: "239", phoneLength: 7 },
  { code: "SV", label: "El Salvador", phone: "503", phoneLength: 8 },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    phoneLength: 10,
  },
  { code: "SY", label: "Syrian Arab Republic", phone: "963", phoneLength: 7 },
  { code: "SZ", label: "Swaziland", phone: "268", phoneLength: 8 },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    phoneLength: 10,
  },
  { code: "TD", label: "Chad", phone: "235", phoneLength: 6 },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    phoneLength: 10,
  },
  { code: "TG", label: "Togo", phone: "228", phoneLength: 8 },
  { code: "TH", label: "Thailand", phone: "66", phoneLength: 9 },
  { code: "TJ", label: "Tajikistan", phone: "992", phoneLength: 9 },
  { code: "TK", label: "Tokelau", phone: "690", phoneLength: 5 },
  { code: "TL", label: "Timor-Leste", phone: "670", phoneLength: 7 },
  { code: "TM", label: "Turkmenistan", phone: "993", phoneLength: 8 },
  { code: "TN", label: "Tunisia", phone: "216", phoneLength: 8 },
  { code: "TO", label: "Tonga", phone: "676", phoneLength: 5 },
  { code: "TR", label: "Turkey", phone: "90", phoneLength: 11 },
  { code: "TT", label: "Trinidad and Tobago", phone: "1-868", phoneLength: 7 },
  { code: "TV", label: "Tuvalu", phone: "688", phoneLength: 5 },
  {
    code: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
    phoneLength: 9,
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
    phoneLength: 7,
  },
  { code: "UA", label: "Ukraine", phone: "380", phoneLength: 9 },
  { code: "UG", label: "Uganda", phone: "256", phoneLength: 7 },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
    phoneLength: 10,
  },
  { code: "UY", label: "Uruguay", phone: "598", phoneLength: 8 },
  { code: "UZ", label: "Uzbekistan", phone: "998", phoneLength: 9 },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    phoneLength: 10,
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    phoneLength: 7,
  },
  { code: "VE", label: "Venezuela", phone: "58", phoneLength: 7 },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
    phoneLength: 7,
  },
  { code: "VI", label: "US Virgin Islands", phone: "1-340", phoneLength: 10 },
  { code: "VN", label: "Vietnam", phone: "84", phoneLength: 9 },
  { code: "VU", label: "Vanuatu", phone: "678", phoneLength: 5 },
  { code: "WF", label: "Wallis and Futuna", phone: "681", phoneLength: 6 },
  { code: "WS", label: "Samoa", phone: "685", phoneLength: 7 },
  { code: "XK", label: "Kosovo", phone: "383", phoneLength: 8 },
  { code: "YE", label: "Yemen", phone: "967", phoneLength: 9 },
  { code: "YT", label: "Mayotte", phone: "262", phoneLength: 9 },
  { code: "ZA", label: "South Africa", phone: "27", phoneLength: 9 },
  { code: "ZM", label: "Zambia", phone: "260", phoneLength: 9 },
  { code: "ZW", label: "Zimbabwe", phone: "263", phoneLength: 9 },
];

export const dummyDataAddress = {
  "0": {
    id: 3040,
    address: "IC",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "5C75+X96 - International City - Dubai - United Arab Emirates",
    selected_lat_lng: "25.1648925,55.4084034",
    delivery_fee: 0,
  },
  "1": {
    id: 3043,
    address: "Dubai mall",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 1,
    google_selected_address:
      "57WH+VV - Downtown Dubai - Dubai - United Arab Emirates",
    selected_lat_lng: "25.1972295,55.279747",
    delivery_fee: 0,
  },
  "2": {
    id: 3044,
    address: "Fujairah",
    label: "Office",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "48HG+GHH - Al Ittihad - Fujairah - United Arab Emirates",
    selected_lat_lng: "25.1288099,56.3264849",
    delivery_fee: 0,
  },
  "3": {
    id: 3091,
    address: "abudhabi",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "F93G+HW5 - Al Manhal - Abu Dhabi - United Arab Emirates",
    selected_lat_lng: "24.453884,54.3773438",
    delivery_fee: 0,
  },
  "4": {
    id: 3201,
    address: "Sharjah",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "Sharjah International Airport - Sharjah - United Arab Emirates",
    selected_lat_lng: "25.3284352,55.5122577",
    delivery_fee: 0,
  },
  "5": {
    id: 3243,
    address: "Korfakan",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "88RX+MC9 - Sharjah - United Arab Emirates",
    selected_lat_lng: "25.34167,56.34862339999999",
    delivery_fee: 0,
  },
  "6": {
    id: 3244,
    address: "Al Hail , Fujairah",
    label: "Office",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "476P+JR7 - Fujairah - United Arab Emirates",
    selected_lat_lng: "25.1115461,56.2870511",
    delivery_fee: 0,
  },
  "7": {
    id: 3245,
    address: "al dhaid",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "Al dhaid main road 1 - Al Tayebah - Sharjah - United Arab Emirates",
    selected_lat_lng: "25.2765754,55.8768659",
    delivery_fee: 0,
  },
  "8": {
    id: 5209,
    address: "Dubai Outsouc city",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H6 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238903,55.42551",
    delivery_fee: 0,
  },
  "9": {
    id: 5219,
    address: "n",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H6 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238901,55.4255187",
    delivery_fee: 0,
  },
  "10": {
    id: 6180,
    address: "nad al hammar",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "692P+5GC - Nadd Al Hamar - Dubai - United Arab Emirates",
    selected_lat_lng: "25.2004324,55.3862638",
    delivery_fee: 0,
  },
  "11": {
    id: 6857,
    address: "Ajman",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "CG47+3CX - Al Hamidiya 1 - Ajman - United Arab Emirates",
    selected_lat_lng: "25.4052165,55.5136433",
    delivery_fee: 0,
  },
  "12": {
    id: 7543,
    address: "Doc",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H6 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238932,55.4255005",
    delivery_fee: 0,
  },
  "13": {
    id: 7544,
    address: "Home",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "5C75+X96 - International City - Dubai - United Arab Emirates",
    selected_lat_lng: "25.1648925,55.4084034",
    delivery_fee: 0,
  },
  "14": {
    id: 7545,
    address: "Uuh",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H6 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238932,55.4255005",
    delivery_fee: 0,
  },
  "15": {
    id: 7546,
    address: "Sharjah",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "9C4G+FV8 - Al Ramla - Al Ramla - East - Sharjah - United Arab Emirates",
    selected_lat_lng: "25.3561698,55.427211",
    delivery_fee: 0,
  },
  "16": {
    id: 7547,
    address: "4CFG+H5 Dubai - United Arab Emirates",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H5 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238934,55.4254997",
    delivery_fee: 0,
  },
  "17": {
    id: 7548,
    address: "Dubai",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "4CFG+H5 Dubai - United Arab Emirates",
    selected_lat_lng: "25.1238934,55.4254997",
    delivery_fee: 0,
  },
  "18": {
    id: 7746,
    address: "korfakan",
    label: "Office",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "88RX+MFF - Al Bardi 2 - Sharjah - United Arab Emirates",
    selected_lat_lng: "25.3416881,56.3486792",
    delivery_fee: 0,
  },
  "19": {
    id: 8190,
    address: "48HG+GHH - Al Ittihad - Fujairah - United Arab Emirates",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "48HG+GHH - Al Ittihad - Fujairah - United Arab Emirates",
    selected_lat_lng: "25.1288099,56.3264849",
    delivery_fee: 0,
  },
  "20": {
    id: 8222,
    address: "48HG+GHH - Al Ittihad - Fujairah - United Arab Emirates",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "48HG+GHH - Al Ittihad - Fujairah - United Arab Emirates",
    selected_lat_lng: "25.1288099,56.3264849",
    delivery_fee: 0,
  },
  "21": {
    id: 10324,
    address: "Al Ain",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "4RJ2+3WG - Al Ruwaydat - Military Zone - Abu Dhabi - United Arab Emirates",
    selected_lat_lng: "24.1301619,55.8023118",
    delivery_fee: 0,
  },
  "22": {
    id: 10716,
    address: "Flat no 100\n12th floor\nABCD Buidling , Dubai , pin 0000",
    label: "Home",
    area: null,
    instrunctions: "Call me when you reach on 05012345678",
    is_set: 0,
    google_selected_address: "673C+W8M - Dubai - United Arab Emirates",
    selected_lat_lng: "25.2048493,55.2707828",
    delivery_fee: 0,
  },
  "23": {
    id: 12355,
    address: "116 Home\nDeira Tower , Dubai",
    label: "Home",
    area: null,
    instrunctions: "Call once you reached here",
    is_set: 0,
    google_selected_address:
      "6759+3VR - Al Satwa - Dubai - United Arab Emirates",
    selected_lat_lng: "25.20785854268172,55.2695060685089",
    delivery_fee: 0,
  },
  "24": {
    id: 14841,
    address: "8G99+VQ Sharjah - United Arab Emirates",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "8G99+VQ Sharjah - United Arab Emirates",
    selected_lat_lng: "25.319743,55.5194281",
    delivery_fee: 0,
  },
  "25": {
    id: 16333,
    address: "Fujairah Airport",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "486M+MWH - Fujairah International Airport - Fujairah - United Arab Emirates",
    selected_lat_lng: "25.1115139,56.3346093",
    delivery_fee: 0,
  },
  "26": {
    id: 16571,
    address: "Fujaiara",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "Fujaiara",
    selected_lat_lng: "undefined,undefined",
    delivery_fee: 0,
  },
  "27": {
    id: 17683,
    address: "Al Ragayeb1 , Inside Nesto Hypermarket , Ajman",
    label: "Others",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "Al Ragayeb1 , Inside Nesto Hypermarket , Ajman",
    selected_lat_lng: "undefined,undefined",
    delivery_fee: 0,
  },
  "28": {
    id: 22326,
    address: "Deira",
    label: "Office",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address:
      "Sobha Road Aida Building - near Al Madina Market - ديرة - دبي - United Arab Emirates",
    selected_lat_lng: "25.2788468,55.3309395",
    delivery_fee: 0,
  },
  "29": {
    id: 22341,
    address: "home",
    label: "Home",
    area: null,
    instrunctions: null,
    is_set: 0,
    google_selected_address: "8988+3C, Baghdad, Baghdad Governorate, Iraq",
    selected_lat_lng: "33.315241,44.3660671",
    delivery_fee: 0,
  },
  count_labels: {
    Home: 20,
    Office: 4,
    Others: 6,
  },
};
export const googleMarkerIcon = {
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 2,
  // width: "39px",
  // height: "39px",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  pointerEvents: "none",
} as React.CSSProperties;
export function isValidURL(value: string) {
  var res =
    value !== null
      ? value.match(
          /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        )
      : null;
  return res !== null;
}

export const DATE_FORMAT_STRING = "DD/MMM/YY ";
export const DATE_FORMAT_YYYYMMDD = "YYYY-MM-DD";
export const DAYS_NAME_NUMBER = {
  0: "sunday",
  1: "monday",
  2: "tuesday",
  3: "wednesday",
  4: "thursday",
  5: "friday",
  6: "saturday",
};
export type OBJECT_KEY_DAYS = keyof typeof DAYS_NAME_NUMBER;
export interface ISELECTED_DATE_TIME {
  dateTimeString: string;
  dateInNumber: string;
  timeSlot: string;
  isCustom: boolean;
  selectedDate: string;
  formatedDayName: string;
}
export interface ProductCartItem {
  id: string;
  prdId: number;
  sku: string;
  name: string;
  slug: string;
  price: string;
  new: boolean;
  rating: number;
  saleCount: number;
  category: string[];
  categoryWithSlugID: Record<string, string>;
  tag: string[];
  stock: number;
  img_aws: string;
  image: string[];
  product_faqs: any[];
  sPageDeliveryFee: any[];
  sPageEnableOTP: string;
  is_customized: string;
  inventory_un_available_reason: {
    en: string;
    ar: string;
  };
  shortDescription: string | null;
  fullDescription: string | null;
  theme_desc: string | null;
  discount: string | null;
  colors: string | null;
  color_image: string | null;
  extra_options: any[] | null;
  variations: Record<
    string,
    Record<
      string,
      {
        stock: number;
        description: string;
        price: string;
        image: string;
      }
    >
  >;
  specifications: string | null;
  isAvailable: string;
  reviews: any[];
  quantity: number;
  selectedProductColor: string | null;
  selectedProductSize: string | null;
  selectedLableValue: {
    firstLabel: {
      key: string;
      value: string;
    };
  };
  extraOptions: any[];
  actualPrice: string;
  discountedPrice: string | null;
  variation: {
    الحجم: string;
    img: string;
    price: string;
    description: string;
  };
  cartItemId: string;
}
