import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import PhoneInput from "react-phone-number-input";
// import "react-phone-input-2/lib/style.css";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput, { CountryData } from "react-phone-input-2";
import useSendOtp from "../../../hooks/useSendOtp";
import {
  getProxyData,
  IProxyIp,
  isNotValidNumber,
} from "../../../shared/commonUtils";
import ButtonCommon from "../../button-common/ButtonCommon";
import { t } from "i18next";
import CrossSvg from "../../../assets/svgs/CrossSvg";
import { ProfileLogin } from "../../../assets/svgs/svgs";
import { toast } from "react-toastify";
import { showErrortMessage } from "../../../shared/helpersMethod";
import ModalCloseButton from "../components/ModalCloseButton";
// import "react-phone-number-input/style.css";
interface IValidateOtp {
  onCloseModal: () => void;
  onPressSend: (countryCode: string) => void;
  show: boolean;
  setCustomerMobile: (mobile: string) => void;
}
const SendOtp: React.FC<IValidateOtp> = (props: IValidateOtp) => {
  const { show, onCloseModal, onPressSend, setCustomerMobile } = props;
  const [value, setValue] = useState();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>("");
  const [ccS, setCCS] = useState<string>("iq");
  // const otpTemp: any = {
  //   data: {
  //     msisdn: "923105599915",
  //     req_id: "4762fa81-e465-4319-8cc4-aaca6c99992a",
  //     sms_id: 17240,
  //     status: "SUCCESS",
  //   },
  //   message: "Customer profile data",
  //   type: "success",
  // };

  const { sendOtpHandler, loading, otpData, errorMessage } = useSendOtp();

  const openValidateModel = async () => {
    // console.log("heeloo");

    // if (isNotValidNumber(userPhone, selectedCC))
    //phoneNumber
    if (phoneNumber.length - countryCode.length !== 0) {
      var objIsNotValidNumber = isNotValidNumber(phoneNumber, countryCode, ccS);

      if (objIsNotValidNumber.isNotValid) {
        showErrortMessage("Invalid number ");
        setShowError(true);
      } else {
        // uncomment in production
        sendOtpHandler(objIsNotValidNumber.validatePhone, countryCode);
        setCustomerMobile(objIsNotValidNumber.validatePhone);
        // for dev purposes
        /*localStorage.setItem("otpDataTemp", JSON.stringify(otpTemp));
        navigate(`/${bussinessName}/validate-otp`, {
          state: { otpTemp },
          replace: true,
        });
        */
      }
    } else {
      setShowError(true);
    }
  };
  const getValues = async () => {
    const res: IProxyIp = await getProxyData();
    localStorage.setItem("userIpInfo", JSON.stringify(res));
    if (res) {
      setCCS(res?.countryCode);
    }
  };
  useEffect(() => {
    if (otpData) {
      onPressSend(countryCode);
    }
  }, [otpData]);
  useEffect(() => {
    getValues();
  }, []);
  return (
    <div>
      <Modal animation={false} show={show}>
        <ModalCloseButton onPressCross={onCloseModal} />
        <div
          className="ml-20  mr-20 "
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <div className="otp-title-wrapper">
              <ProfileLogin />
              <div className="enter-mobile">{t("enter_mobile_number")}</div>
            </div>
            <div className="mt-10 mb-10" style={{ color: "#828282" }}>
              {t("enter_mobile_number_txt")}
            </div>
          </div>
          {/* <div style={{ direction: "ltr" }}> */}
          <ReactPhoneInput
            containerStyle={{ direction: "ltr" }}
            inputClass={showError ? "border-error " : ""}
            countryCodeEditable={false}
            country={ccS.toLowerCase()}
            enableLongNumbers={true}
            enableSearch={false}
            preferredCountries={["ae", "iq"]}
            value={value}
            onChange={(phone, data: CountryData) => {
              setPhoneNumber(phone);
              setCCS(data.countryCode);
              setCountryCode(data.dialCode);
              setShowError(false);
            }}
          />
          {/* </div> */}
          {errorMessage !== "" && (
            <div className="text-danger mt-20" style={{ fontSize: 12 }}>
              {errorMessage}
            </div>
          )}
        </div>
        <div
          className="mt-25 mb-25 "
          style={{ width: 330, alignSelf: "center" }}
        >
          <ButtonCommon
            onPressButton={openValidateModel}
            loading={loading}
            btnLabelText={t("send_code")}
          />
        </div>
      </Modal>
    </div>
  );
};

export default SendOtp;
