export const CSS_VARIABLES = {
  singleBannerColorLeft: "--single-banner-color-left",
  singleBannerColorRight: "--single-banner-color-right",
  heroSliderColor: "--hero-slider-color",
  buttonColor: "--button-color",
  mouseHoverColor: "--mouse-hover-color",
  mouseSelectionColor: "--mouse-selection-color",
  productTextColor: "--product-tex-color",
  sliderTextColor: "--slider-text-color",
  sliderBgColor: "--slider-background-color",
  featuredProductColor: "--product-text-color",
  categorySliderBtnColorInActive: "--categorySliderBtnColorInActive",
  categorySliderBtnColorActive: "--categorySliderBtnColorActive",
  themeColorLight: "--theme-color-light",
  themeBorder: "--theme-border",
  // siteFeaturedProductPrimaryBackgroundColor:"",
  // siteFeaturedProductSecondaryBackgroundColor:"",
};

// --single-banner-color-left:#C7D2E3;
// --single-banner-color-right:#DFE7F1;
// --hero-slider-color:#DFE9F1;

/**
 * 
 * 
 *  --single-banner-color-left:#C7D2E3;
    --single-banner-color-right:#DFE7F1;
    --button-color:"blue";
    --mouse-hover-color:"#C7D2E3";
    --mouse-selection-color:"#C7D2E3";
    --product-tex-color:"#000";
    --slider-text-color:"#000";
    --slider-background-color:"#DFE9F1";
 */
