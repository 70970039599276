import useTranslationHook from "../../../hooks/useTranslationHook";
import useGetSearchData from "../../../hooks/useSearchInput";
import { Link } from "react-router-dom";
const MobileSearch = () => {
  const { loading, getSearchResult, serachData } = useGetSearchData();
  const { t } = useTranslationHook();
  const handleChange = (e: any) => {
    getSearchResult(e.target.value);
    // setSearchTerm(e.target.value);
    // Trigger search after a delay (e.g., 300ms)

    // debounceSearch(e.target.value);
  };
  return (
    <>
      <div className="offcanvas-mobile-search-area">
        <form action="#">
          <input
            type="search"
            placeholder={t("txt_search")!}
            onChange={handleChange}
          />
          <button type="submit">
            <i className="fa fa-search" />
          </button>
        </form>
      </div>
      {serachData.length > 0 && (
        <div className="search-form-data ">
          {serachData.map((item) => {
            return (
              <Link
                to={
                  item.source === "products"
                    ? `/product/${item.data_id}`
                    : `/collections/${item.data_id}`
                }
              >
                <div className="search-form-data-txt" key={item.data_id}>
                  {item.name}
                </div>
              </Link>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MobileSearch;
