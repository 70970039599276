// ProductOptions.js
import React, { useEffect, useState } from "react";
import { Form, Col, Card, Container, Row, FormLabel } from "react-bootstrap";

const ProductOptions = ({
  extraOptions,
  handleOptionChange,
  selectedOptions,
}) => {
  // const getSelectedCount = (optionKey, itemId) => {
  const [currentSOA, setCurrentSOA] = useState([]);
  const [tempArray, settempArray] = useState([]);
  const getSelectedCount = (itemId, arrayCurrentItems) => {
    var count = 0;
    tempArray.forEach((element) => {
      if (element.id == itemId) {
        count = count + 1;
      }
      return;
    });
    return count;
  };
  // const getSelectedCount = React.useMemo(() => {
  //   console.log('caleed')
  //   return ( itemId) => {
  //     const selectedItems =currentSOA;

  //     const selectedItem = selectedItems.find((item) => item.id === itemId);
  //     return selectedItem ? selectedItem.quantity : 0;
  //   };
  // }, [currentSOA]);

  const handleCheckBoxChange = (optionID, item) => {
    console.log(optionID);
    var currentSelectedOptions = currentSOA;
    const selectedItemIndex = currentSelectedOptions.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    const isOptionBox = item.item_type == "option" || item.item_type == null ? true : false;
    const isOptionBoxExist =
      isOptionBox &&
      currentSelectedOptions.some(function (element) {
        return element.main_id == optionID ? true : false;
      }); // -> true

    if (isOptionBox) {
      if (!isOptionBoxExist) {
        console.log("!isOptionBoxExist");

        // Use the callback form of setCurrentSOA
        setCurrentSOA((prevSelectedOptions) => {
          // Create a new array with the updated item
          const updatedOptions = [
            ...prevSelectedOptions,
            {
              ...item,
              quantity: 1,
              main_id: optionID,
            },
          ];

          handleOptionChange(optionID, updatedOptions);

          return updatedOptions;
        });

        // return;
      }
      if (isOptionBoxExist) {
        // console.log('isOptionBoxExist')
        // this line will if optiopn already exist for some main id
        currentSelectedOptions = currentSelectedOptions.filter(
          (value) => value.id !== item.id && value.main_id !== optionID
        );
        currentSelectedOptions.push({
          ...item,
          quantity: 1,
          main_id: optionID,
        });

        setCurrentSOA((prev) => [
          // value.id !== item.id
          ...prev.filter(
            (value) => value.id !== item.id && value.main_id !== optionID
          ),
          // ...prev.filter((value) => value.main_id === optionID),
          // ...prev.filter((value) => value.item_type === "check-box"  ), // Keep only checkboxes
          { ...item, quantity: 1, main_id: optionID }, // Add the new item
        ]);

        // return;
      }
      // console.log("hello : ", currentSelectedOptions);
      handleOptionChange(optionID, currentSelectedOptions);

      return;
    }
    // item with type checkbox
    if (selectedItemIndex !== -1) {
      // console.log()

      // console.log("condition after if ");
      // Item already selected, update the quantity
      currentSelectedOptions[selectedItemIndex].quantity += 1;

      // getSelectedCount(currentSelectedOptions[selectedItemIndex].id)
    } else {
      // Item not selected, add to the list with quantity 1
      currentSelectedOptions.push({ ...item, quantity: 1, main_id: optionID });
    }
    settempArray((prev) => [...prev, item]);
    setCurrentSOA(currentSelectedOptions);
    // getSelectedCount(currentSelectedOptions[selectedItemIndex].id,currentSelectedOptions)
    // Ensure selectedOptions[optionKey] is consistently an array
    handleOptionChange(optionID, currentSelectedOptions);
  };

  return (
    <Container>
      {Object.keys(extraOptions).map((optionKey) => {
        const option = extraOptions[optionKey];
        const isMandatory = option.is_mandatory === "Yes";
        const selectedItems = selectedOptions || [];

        return (
          <Col key={option.id} className="mb-3">
            {/* <Card> */}

            <Card.Body className="mb-15">
              <h4 className="mb-10">{option.name}</h4>
              <div className="option-button-style">
                {/* <Form> */}
                {option.items.map((item) => (
                  <React.Fragment key={item.id}>
                    {/* option-selected */}
                    {item.item_type !== "check-box" ? (
                      // <div
                      // onClick={() => handleCheckBoxChange(option.id, item)}
                      // className={`option-button-inner ${currentSOA.some((selectedItem) =>  selectedItem.id === item.id) &&'option-selected' }`}>

                      //   {item.name}

                      // </div>
                      <div
                        className={`option-button-inner ${
                          currentSOA.some(
                            (selectedItem) => selectedItem.id === item.id
                          ) && "option-selected"
                        }`}
                        dangerouslySetInnerHTML={{ __html: item.name }}
                        onClick={() => handleCheckBoxChange(option.id, item)}
                      />
                    ) : (
                      // <Form.Check
                      //   type={"radio"}
                      //   id={item.id}
                      //   label={item.name}
                      //   checked={currentSOA.some(
                      //     (selectedItem) => selectedItem.id === item.id
                      //   )}
                      //   onChange={() => handleCheckBoxChange(option.id, item)}
                      // />
                      <div
                        className={`option-multi-select d-flex justify-content-between option-button-inner`}
                        // className="d-flex justify-content-between "
                        onClick={() => handleCheckBoxChange(option.id, item)}
                      >
                        <FormLabel>{item.name}</FormLabel>
                        {
                          <div>
                            {getSelectedCount(item.id)}
                            <span>x</span>
                          </div>
                        }
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              {/* </Form> */}
              {/* {isMandatory && (
                  <p className="mt-3">
                    Selected: {selectedItems.length}/{option.mandatory_amount}
                  </p>
                )} */}
            </Card.Body>
            {/* </Card> */}
          </Col>
        );
      })}
    </Container>
  );
};

export default ProductOptions;
