import PropTypes from "prop-types";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

const BrandLogoOneSingle = ({ data, spaceBottomClass }) => {
  const navigate = useNavigate();
  const onClickHandler = () => {
    navigate(`/collections?q=brand&slug=${data.slug}`);
  };
  return (
    <div
      onClick={onClickHandler}
      className={clsx("single-brand-logo", spaceBottomClass)}
    >
      <img src={data.image} />
    </div>
  );
};

BrandLogoOneSingle.propTypes = {
  data: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
};

export default BrandLogoOneSingle;
