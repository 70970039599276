import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { setCurrency } from "../../../store/slices/currency-slice";
import { useEffect } from "react";
import { IHOMEPAGEData } from "../../../shared/commonUtils";
import {
  getUserPrefersLang,
  saveUserPrefersLang,
  setBussinessCurrency,
} from "../../../shared/helpersMethod";

const LanguageCurrencyChanger = ({ currency }: any) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const currentLang = getUserPrefersLang();
  const callUsHandler = (phoneNumber: string) => {
    document.location.href = `tel:${phoneNumber}`;
    // dispatch(setCloseAllModal());
  };
  const changeLanguageTrigger = (e: any) => {
    // console.log(e.target.value);
    const languageCode = e.target.value;

    i18n.changeLanguage(languageCode);
    if (languageCode === "ar") {
      // GoogleTranslate();
    }
    saveUserPrefersLang(languageCode);

    document
      .getElementsByTagName("html")[0]
      .setAttribute("lang", languageCode === "ar" ? "ar" : "en");
    document
      .getElementsByTagName("body")[0]
      .setAttribute("dir", languageCode === "ar" ? "rtl" : "ltr");
    window.location.reload();
  };

  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  const setCurrencyTrigger = (e: any) => {
    const currencyName = e.target.value;
    dispatch(setCurrency(currencyName));
    setBussinessCurrency(currencyName);
  };
  useEffect(() => {
    if (bussinessData && Object.keys(bussinessData).length !== 0) {
      setBussinessCurrency(bussinessData.businessMetas.BUSSINESS_CCY);
      dispatch(setCurrency(bussinessData.businessCurrency));
      if (currentLang === null) {
        i18n.changeLanguage(bussinessData.defaultLang);
        saveUserPrefersLang(bussinessData.defaultLang);
        getUserPrefersLang();
        if (bussinessData.defaultLang === "ar") {
          document.getElementsByTagName("html")[0].setAttribute("lang", "ar");
          document.getElementsByTagName("body")[0].setAttribute("dir", "rtl");
        }
        return;
      }
      if (currentLang !== null) {
        i18n.changeLanguage(currentLang);
      }
    }
    // console.log(bussinessData);
  }, [bussinessData]);

  return (
    <div className="language-currency-wrap">
      <div className="same-language-currency language-style">
        <span>
          {currentLang === "en"
            ? "English"
            : currentLang === "ar"
              ? "عربي"
              : ""}{" "}
          <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button value="en" onClick={(e) => changeLanguageTrigger(e)}>
                English
              </button>
            </li>
            <li className="font-arabic">
              <button value="ar" onClick={(e) => changeLanguageTrigger(e)}>
                عربي
              </button>
            </li>
          </ul>
        </div>
      </div>
      {/*  
      <div className="same-language-currency use-style">
        <span>
          {currency.currencyName} <i className="fa fa-angle-down" />
        </span>
        <div className="lang-car-dropdown">
          <ul>
            <li>
              <button
                value={bussinessData.businessCurrency}
                onClick={(e) => setCurrencyTrigger(e)}
              >
                {bussinessData.businessCurrency}
              </button>
            </li>
          </ul>
        </div>
      </div>
      */}

      <div className="same-language-currency">
        <p
          onClick={() => {
            callUsHandler(bussinessData?.site_settings?.siteCallUsPhoneNumber);
          }}
        >
          <i className="fa fa-phone" style={{ padding: "0 8px 0 2px" }}></i>
          {/* <span> {t("call_us_new")}</span> */}
          {t("call_us")}{" "}
          <span dir="ltr">
            {" "}
            {bussinessData?.site_settings?.siteCallUsPhoneNumber}
          </span>
        </p>
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  currency: PropTypes.shape({}),
};

export default LanguageCurrencyChanger;
