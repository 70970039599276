import axios from 'axios';

// TODO Should be fetched form the env file
const baseURL = 'https://orderapi.dukany.io/api/';

export const instanceDukany = axios.create({
  baseURL,
  timeout: 100000,
});

instanceDukany.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export default instanceDukany;
