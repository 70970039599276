import PropTypes from "prop-types";
import clsx from "clsx";
interface ISectionTitleWithText {
  welcomeInfo: any;
  spaceTopClass?: any;
  spaceBottomClass?: any;
}
const SectionTitleWithText: React.FC<ISectionTitleWithText> = (
  props: ISectionTitleWithText
) => {
  const { spaceTopClass, spaceBottomClass, welcomeInfo } = props;
  return (
    <div className={clsx("welcome-area", spaceTopClass, spaceBottomClass)}>
      <div className="container">
        <div className="welcome-content ml-15 mr-15 ">
          <div
            dangerouslySetInnerHTML={{ __html: welcomeInfo }}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          />
        </div>
      </div>
    </div>
  );
};

SectionTitleWithText.propTypes = {
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
};

export default SectionTitleWithText;
