import PropTypes from "prop-types";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import BreadcrumbDivider from "../../assets/svgs/BreadcrumDivider.svg"; // Import your SVG

import { Link } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

const BreadcrumbWrap = ({ pages }) => {
  return (
    <div className="breadcrumb-area  bg-gray-3">
      <div className="container">
        <Breadcrumb>
          {pages?.map(({ path, label }, i) =>
            i !== pages.length - 1 ? (
              <Fragment>
                <Breadcrumb.Item
                  key={label}
                  linkProps={{ to: path }}
                  linkAs={Link}
                >
                  {label?.replace("-", " ")}
                </Breadcrumb.Item>
                <img
                  src={BreadcrumbDivider}
                  alt="chevron"
                  class="breadcrumb-separator"
                />
              </Fragment>
            ) : (
              <Breadcrumb.Item key={label} active>
                {label?.replace("-", " ")}
              </Breadcrumb.Item>
            )
          )}
        </Breadcrumb>
      </div>
    </div>
  );
};

BreadcrumbWrap.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default BreadcrumbWrap;
