import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { useState,useEffect } from "react";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";

const HeaderTop = ({ borderStyle }) => {
  const currency = useSelector((state) => state.currency);
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const headerBanner = bussinessData?.site_settings?.siteHeaderDynamicText;
  const [currentSentence, setCurrentSentence] = useState(0);
  useEffect(() => {
    
    if (headerBanner && headerBanner?.length > 0){
      
    const intervalId = setInterval(() => {
       
      setCurrentSentence((prev) => (prev + 1) % headerBanner?.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }
  }, [currentSentence]);
  return (
    <div
      className={clsx(
        "header-top-wap",
        borderStyle === "fluid-border" && "border-bottom"
      )}
    >
      <LanguageCurrencyChanger currency={currency} />
      {bussinessData &&
        headerBanner &&
        headerBanner.length > 0 &&
        /** 
        headerBanner.map((item, index) => {
          return (
            <div className="header-offer" key={index}>
              <p>
                {item?.description}
                Free delivery on order over{" "}
                <span>
                  {currency.currencySymbol +
                    (200 * currency.currencyRate).toFixed(2)}
                </span>
              </p>
            </div>
            
          );
          
        })*/
        <div className="header-offer" >
              <p className="slideInUp" id="header_Slide_Text">
                {/* {item?.description} */}
                {/* Free delivery on order over{" "} */}
                {/* <span>
                  {currency.currencySymbol +
                    (200 * currency.currencyRate).toFixed(2)}
                </span> */}
                <span >
                {headerBanner[currentSentence]}
                {/* {headerBanner[0]?.description} */}
                </span>
              </p>
            </div>
      }
        
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
