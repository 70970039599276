// import cogoToast from 'cogo-toast';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BreadCrumState {
  category: {
    name: string;
    id: string;
    slug:string;
  };
  product: {
    name: string;
    id: string;
    slug:string;
  
  };
}
interface BreadCrumVal {
  name: string;
  id: string;
  slug:string;
}

const initialState: BreadCrumState = {
  category: { name: "", id: "",slug:"", },
  product: { name: "", id: "" ,slug:""},
};

const breadCrumSlice = createSlice({
  name: "breadCrumValues",
  initialState,
  reducers: {
    setBreadCrumCategoryName(state, action: PayloadAction<BreadCrumVal>) {
      state.category = action.payload;
      state.product = { id: "", name: "",slug:"" };
      // cogoToast.success("Added To compare", {position: "bottom-left"});
    },
    setBreadCrumProductName(state, action: PayloadAction<BreadCrumVal>) {
      state.product = action.payload;
      // cogoToast.success("Added To compare", {position: "bottom-left"});
    },
  },
});

export const { setBreadCrumCategoryName, setBreadCrumProductName } =
  breadCrumSlice.actions;
export default breadCrumSlice.reducer;
