import { EffectFade } from "swiper";
import Swiper, { SwiperSlide } from "../../components/swiper";
import sliderData from "../../data/hero-sliders/hero-slider-six.json";
import HeroSliderSixSingle from "../../components/hero-slider/HeroSliderSixSingle.js";
import React from "react";
import { Slide } from "../../shared/commonUtils";
import { useSelector } from "react-redux";

const params = {
  effect: "fade",
  fadeEffect: {
    crossFade: true,
  },
  modules: [EffectFade],
  loop: true,
  speed: 1000,
  navigation: true,
  autoHeight: false,
};
interface IHeroSliderSix {}
const HeroSliderSix: React.FC<IHeroSliderSix> = (props: IHeroSliderSix) => {
  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  const homePageSlider =
    bussinessData?.homepage_configurations?.homepage_slider?.slides;
  return (
    <div className="slider-area">
      <div className="slider-active nav-style-1">
        {homePageSlider && (
          <Swiper options={params}>
            {homePageSlider.map((single, key) => (
              <SwiperSlide key={key}>
                <HeroSliderSixSingle data={single} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default HeroSliderSix;
