import { Fragment, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDiscountPrice } from "../../helpers/product";
import { useTranslation } from "react-i18next";
import SEO from "../../components/seo";
import useTranslationHook from "../../hooks/useTranslationHook";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import Rating from "../../components/product/sub-components/ProductRating";
import { addToCart } from "../../store/slices/cart-slice";
import { deleteFromCompare } from "../../store/slices/compare-slice";
import DoneIconSvg from "../../assets/svgs/DoneIconSvg";
import useCustomerInfo from "../../hooks/useCustomerInfo";
import HomeSvg from "../../assets/svgs/HomeSvg";
import { getUserPrefersLang } from "../../shared/helpersMethod";
import LocationSvg from "../../assets/svgs/LocationSvg";
import TrackOrderStatus from "../../components/track-order-single/TrackOrderStatus";

const TrackOrderPage: React.FC = () => {
  let { pathname } = useLocation();
  const { t } = useTranslationHook();
  const selectedLanguageCode = getUserPrefersLang();
  const { trackingOrder, trackOrderVal, loading } = useCustomerInfo();
  let [searchParams, setSearchParams] = useSearchParams();
  const refId = searchParams.get("ref");
  const orderId = searchParams.get("order");
  useEffect(() => {
    // order=34876&ref=21910
    trackingOrder(orderId!, refId!, "en");
  }, []);
  return (
    <Fragment>
      <SEO titleTemplate="Track Order" description="Easily track your order" />
      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: t("txt_home"), path: process.env.PUBLIC_URL + "/" },
            {
              label: t("txt_track_order"),
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="compare-main-area pt-20 pb-100">
          <div className="container " style={{ width: "85%" }}>
            {/* <!-- content start here --> */}
            <div className="cart_content d-flex flex-column ">
              <>
                {!loading ? (
                  <div
                    id="pop_cart_page7"
                    className="pop_up_maps_main"
                    // lang="ar"
                  >
                    <div className=" popup_cart_full popup_from_bottom">
                      <div className="content" id="">
                        {/* <!-- content start here --> */}
                        <div className="cart_content_alert ">
                          {t("order_number")} :
                          <span
                            // onClick={openReceiptHandler}
                            className="openReceiptButton"
                          >
                            #{refId}
                          </span>
                        </div>

                        <div
                          className="cart_content"
                          style={{
                            direction:
                              selectedLanguageCode === "en" ? "ltr" : "rtl",
                          }}
                        >
                          <div
                            className="d-flex pad_bot_10"
                            style={{ justifyContent: "space-between" }}
                          >
                            <div>
                              {/* <div className="cart_time_bg">18:15</div> */}
                              <div className="cart_time_bg">
                                {trackOrderVal?.resto?.estimated_time}
                              </div>
                            </div>
                            <div style={{ lineHeight: "34px" }}>
                              <div className="light_grey_text align_rig">
                                {t("estimated_time")}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex pb-10 title_acc_cell">
                            {t("order_progress")}
                          </div>
                          {/*  */}
                          {/* {trackOrderVal !== undefined && */}
                          {/* // (trackOrderVal?.orderactivities[3]?.status !== "Rejected" ? ( */}
                        </div>
                        {trackOrderVal &&
                          trackOrderVal.orderactivitiesNew &&
                          trackOrderVal?.orderactivitiesNew.map(
                            (item: any, index: number, array: any) => {
                              return (
                                <TrackOrderStatus
                                  backgroundColor={
                                    trackOrderVal?.resto?.busniess_colors
                                      ?.GENERAL_COLOR_THEME
                                  }
                                  array={array}
                                  index={index}
                                  trackOrderVal={item}
                                  selectedLanguageCode={selectedLanguageCode}
                                />
                              );
                            }
                          )}
                        {/* <!-- content end here --> */}
                      </div>

                      {/* <br />
        <br />
        <br />
        <br /> */}
                      <div>
                        <div
                          className="track_order_bussiness_details"
                          style={{
                            backgroundColor:
                              trackOrderVal?.resto?.busniess_colors
                                ?.HIGHLIGHTED_BACKGROUND_COLOR,
                          }}
                        >
                          <div
                            style={{
                              width: "7%",
                            }}
                            className="cart_content_col_15"
                          >
                            <LocationSvg height={26} width={19} />
                          </div>
                          <div>
                            <div style={{ fontWeight: "600" }}>
                              {
                                trackOrderVal?.resto?.nameNew[
                                  selectedLanguageCode
                                ]
                              }
                            </div>
                            <div style={{ fontSize: 12 }}>
                              {
                                trackOrderVal?.resto?.addressNew[
                                  selectedLanguageCode
                                ]
                              }
                            </div>
                          </div>
                        </div>
                        {/* <BottomButton
                          displayBottomLine={false}
                          titleProp={t("call_us")}
                          onPress={closeAllPops}
                          widthProp={375}
                        /> */}
                      </div>
                    </div>
                    {/* {showReceipt && (
                      <OrderReceipt
                        onPressClose={() => setShowReceipt(false)}
                        orderDetails={trackOrderVal}
                      />
                    )} */}
                  </div>
                ) : (
                  <div className={" loader-wrapper"}>
                    {/* TODO: ADD LOADER IN FUTURE */}
                  </div>
                )}
              </>
            </div>
            {/* <!-- content end here --> */}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default TrackOrderPage;
