import React, { useCallback, useEffect, useState } from "react";
import useSendOtp from "../../../hooks/useSendOtp";
import { useTranslation } from "react-i18next";
import VerificationInput from "react-verification-input";
import { Modal } from "react-bootstrap";
import { IOTPCUSTRES } from "../../../shared/commonUtils";
import ModalCloseButton from "../components/ModalCloseButton";
import { ProfileLogin } from "../../../assets/svgs/svgs";
interface IPopPageValidateOtpProps {
  onCloseModal: () => void;
  countryCode: string;
  userMobileNumber: string;
  onPressSend: (isNew: boolean, customerData: IOTPCUSTRES) => void;
  show: boolean;
}
const PopPageValidateOtp: React.FC<IPopPageValidateOtpProps> = (
  props: IPopPageValidateOtpProps
) => {
  const {
    validOtpHandler,
    loading,
    customerData,
    sendOtpHandler,
    otpData,
    errorMessage,
  } = useSendOtp();
  const [timer, setTimer] = useState(60);
  const { show, onCloseModal, onPressSend, countryCode, userMobileNumber } =
    props;
  const { t } = useTranslation();
  var dataFromStrg = localStorage.getItem("otpDataTemp");
  const InputType = "tel";
  const otpRes = dataFromStrg !== null && JSON.parse(dataFromStrg);
  const [otpMsidn, setOtpMsIdn] = useState<string>(userMobileNumber);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  const resetTimer = async () => {
    await sendOtpHandler(otpMsidn, countryCode);
  };
  // for dev purposes
  // const customerTemp = {
  //   Address: [],
  //   email: null,
  //   id: 16963,
  //   isNew: "0",
  //   mobile_number: "923105599915",
  //   name: "Muhammad Abdullah Rehan",
  // };

  const ShowErrorMessage = (errorMessage: string) => (
    <text>{errorMessage}</text>
  );
  const validateOtpFunction = (value4: string) => {
    let otpCode = value4;

    // uncommnet in production
    validOtpHandler(otpRes.data.req_id, userMobileNumber, otpCode);

    // for dev propus
    /** 
    localStorage.setItem("customerInfo", JSON.stringify(customerTemp));
    if (customerTemp.Address.length === 0 && customerTemp.isNew === "0") {
      // setShowAlert(true);
      navigate(`/${bussinessName}/place-order`, { replace: true });
    }
    */
  };
  const showSelectDateHandler = async () => {
    var customerInfo = {
      // ...tempData,
      ...customerData?.data,
    };
    // switch (tempData?.isNew)
    switch (customerData?.data?.isNew) {
      case "0":
        // console.log(customerInfo);
        localStorage.removeItem("otpDataTemp");
        localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
        onPressSend(false, customerData?.data);
        break;
      case "1":
        localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
        onPressSend(true, customerData?.data);
        break;

      default:
        //for dev
        /** 
          localStorage.setItem("customerInfo", JSON.stringify(customerInfo));
          navigate(`/${bussinessName}/create-customer`, { replace: true });
          */
        break;
    }
  };

  useEffect(() => {
    if (customerData !== undefined && customerData.type === "success") {
      showSelectDateHandler();
    }
  }, [customerData]);
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);
  useEffect(() => {
    if (otpData) {
      if (!timer) {
        setTimer(60);
      }
      setOtpMsIdn(otpData.data.msisdn);
    }
  }, [otpData]);
  useEffect(() => {
    const originalHeight = document.documentElement.clientHeight;
    const input = document.querySelector("input");
    //@ts-ignore
    input.addEventListener("focus", () => {
      document.documentElement.style.height = `${originalHeight}px`;
      document.body.style.height = `${originalHeight}px`;
    });
    // @ts-ignore
    input.addEventListener("blur", () => {
      document.documentElement.style.height = "auto";
      document.body.style.height = "auto";
    });
  });

  return (
    <Modal
      animation={false}
      show={show}
      className="product-quickview-modal-wrapper"
    >
      <ModalCloseButton onPressCross={onCloseModal} />
      <div>
        <div className="popup_cart_full popup_from_bottom  mb-40 ">
          {/* <!-- content start here --> */}
          <div className="cart_content d-flex justify-content-center ml-20 mr-20">
            <div className="cart_content_to_marg">
              <div className="cart_content_row d-flex flex-column">
                <div>
                  {/* <BackArrow /> */}
                  <div className="bold login-txt">
                    <ProfileLogin className="mr-10 ml-10" />
                    {t("txt_login")}
                    {/* {t("verify_number")} */}
                  </div>
                </div>
                {/* <span className="icon_sm">
                  <WhatsappIcon />
                </span> */}
                <span className="verification-msg light_grey_text res_font_size1 align-self-center">
                  {t("send_verification_message")}
                </span>
                {otpRes && (
                  <div className="bold  mobile-number">{userMobileNumber}</div>
                )}
              </div>
              <VerificationInput
                validChars={"[0-9]{0,5}"}
                length={4}
                inputProps={{ type: InputType }}
                autoFocus
                onComplete={(value: string) => {
                  // alert(value);
                  validateOtpFunction(value);
                }}
                classNames={{
                  container: "input-otp-container",
                  character: "input-otp-character",
                  characterSelected: "input-otp-active",
                }}
              />
              {errorMessage !== "" && !loading && (
                <div
                  style={{ marginLeft: 20 }}
                  className={errorMessage !== "" ? "show-error-message" : ""}
                >
                  {ShowErrorMessage(errorMessage)}
                  {/* <text>Pleae enter value</text> */}
                </div>
              )}
              <div className="cart_content_row cart_content_to_marg">
                {timer > 0 ? (
                  <div className="cart_content_alert">
                    {t("verifitcation_code_error")} | {timer}
                  </div>
                ) : (
                  <button
                    className="button-common"
                    onClick={resetTimer}
                    disabled={loading}
                    style={{ width: "100%" }}
                  >
                    {t("get_new_code")}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* <!-- content end here --> */}
        </div>
      </div>
    </Modal>
  );
};

export default PopPageValidateOtp;
