import React, { Fragment } from "react";
import { CloseModalSvg } from "../../../assets/svgs/svgs";
interface IMODALCLOSE {
  onPressCross: () => void;
  title?: string;
  style?: React.CSSProperties;
}
const ModalCloseButton: React.FC<IMODALCLOSE> = (props: IMODALCLOSE) => {
  const { onPressCross, title, style } = props;
  return (
    <div
      style={{ ...style }}
      className="d-flex align-items-center justify-content-between modal-close-wrapper"
    >
      <span className="delivery_head cross-button">{title}</span>
      <div className="cross-button" onClick={onPressCross}>
        <CloseModalSvg />
      </div>
    </div>
  );
};

export default ModalCloseButton;
