import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const apiCallCounterSlice = createSlice({
    name: "apiCallCounter",
    initialState: {
        counter : 0,
    },
    reducers: {
        increaseCounter(state, action) {
           state.counter = state.counter + 1; 
            
        },
        resetCounter(state, action) {
            state.counter  = 0; 
             
         },
    },
});

export const { increaseCounter,resetCounter} = apiCallCounterSlice.actions;
export default apiCallCounterSlice.reducer;
