import React from "react";
import TickIconSvg from "./TickIconSvg";
import { TickSvg } from "./svgs";
// import { ReactComponent as DoneSvg } from "../../../assets/images/done-icon.svg";
interface DoneSvgProps {
  height: number | string;
  width: number | string;
  wrapperHeigth: number | string;
  wrapperWidth: number | string;
  wrapperBgColor: string;
}
const DoneIconSvg: React.FC<DoneSvgProps> = (props: DoneSvgProps) => {
  const { wrapperWidth, wrapperHeigth, wrapperBgColor } = props;

  return <TickSvg />;
};

export default DoneIconSvg;
