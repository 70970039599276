import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import "react-phone-number-input/style.css";
import useCustomerInfo from "../../../hooks/useCustomerInfo";
import useTranslationHook from "../../../hooks/useTranslationHook";
import ButtonCommon from "../../button-common/ButtonCommon";
import {
  getCustomerInfoFromStroge,
  setCustomerInfoFromStroge,
} from "../../../shared/helpersMethod";
import CrossSvg from "../../../assets/svgs/CrossSvg";
import ModalCloseButton from "../components/ModalCloseButton";
import { ProfileLogin } from "../../../assets/svgs/svgs";
interface ICreateNewCustomer {
  onCloseModal: () => void;
  show: boolean;
}
const CreateNewCustomer: React.FC<ICreateNewCustomer> = (
  props: ICreateNewCustomer
) => {
  const { show, onCloseModal } = props;
  const [userName, setUserName] = useState<string>("");
  const customerInfo = getCustomerInfoFromStroge();
  const { t } = useTranslationHook();
  const [showError, setShowError] = useState<boolean>(false);
  const { getCustomerInfo, loading } = useCustomerInfo();
  const createNewCustomerHandler = () => {
    if (userName.length !== 0) {
      // parsedData.data.msisdn
      getCustomerInfo(customerInfo.mobile_number, userName).then((value) => {
        if (!value.exsitingUser) {
          if (customerInfo !== null) {
            var updateCustomer = {
              ...customerInfo,
              name: userName,
              isNew: "0",
            };
            setCustomerInfoFromStroge(updateCustomer);
          }
          onCloseModal();
          //   navigatoToNextPage(value.cutomerData);
        }
      });
    } else {
      setShowError(true);
    }
  };
  return (
    <div>
      <Modal
        animation={false}
        show={show}
        className="product-quickview-modal-wrapper"
      >
        <ModalCloseButton onPressCross={onCloseModal} />
        <div
          className="ml-30  mr-30 "
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <span className="bold">
              <ProfileLogin className="mr-5 ml-5" />
              <span className="login-txt">{t("txt_login")}</span>
            </span>
            {/* <h3>{t("create_new_acount")}</h3> */}
            {/* <h5 className="mt-10" style={{ color: "#828282" }}>
                  {t("create_new_acount_txt")}
                </h5> */}

            <div className="col-lg-12">
              <div className="billing-info mb-20 mt-20">
                <label>{t("enter_your_name")}</label>
                <input
                  type="text"
                  onChange={(e) => {
                    setUserName(e.target.value);
                    setShowError(false);
                  }}
                />
                {showError && (
                  <label style={{ color: "red" }}>{t("enter_your_name")}</label>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="mt-25 mb-25 "
          style={{ width: 300, alignSelf: "center" }}
        >
          <ButtonCommon
            btnLabelText={t("create_button")}
            onPressButton={createNewCustomerHandler}
            loading={loading}
          />
        </div>
      </Modal>
    </div>
  );
};

export default CreateNewCustomer;
