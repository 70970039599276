import React, { Fragment } from "react";
import SEO from "../../components/seo";
import LayoutTwo from "../../layouts/LayoutTwo";
import HeroSliderSix from "../../wrappers/hero-slider/HeroSliderSix";
import BannerSix from "../../wrappers/banner/BannerSix";
import TabProductFour from "../../wrappers/product/TabProductFour";
import TestimonialOne from "../../wrappers/testimonial/TestimonialOne";
import NewsletterTwo from "../../wrappers/newsletter/NewsletterTwo";
import BannerSeven from "../../wrappers/banner/BannerSeven";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import BannerThree from "../../wrappers/banner/BannerThree";

const HomeFlowerShop = () => {
  const { bussinessData } = useSelector((state: any) => state.bussinessData);
  return (
    <div>
      {bussinessData && Object.keys(bussinessData).length !== 0 ? (
        <Fragment>
          <SEO
            titleTemplate="Flower Shop Home"
            description="Flower Shop home of flone react minimalist eCommerce template."
          />
          <LayoutTwo>
            {/* hero slider */}
            <HeroSliderSix />
            {/* banner */}
            <BannerSix spaceTopClass="pt-100" spaceBottomClass="pb-80" />
            {/* <BannerThree
              // spaceBottomClass="pb-100"
              bannerData={
                bussinessData?.homepage_configurations
                  ?.homepage_featured_product
              }
            /> */}
            {/* tab product */}
            <TabProductFour
              spaceBottomClass="pb-100"
              category="flower"
              productTabClass="product-tab-pink2"
              homepageCarouselSlider={
                bussinessData?.homepage_configurations?.homepage_carousel_slider
              }
            />
            {/* testimonial */}
            {/* <TestimonialOne /> */}
            {/* banner */}
            {/* <BannerSeven
              spaceTopClass="pt-95"
              spaceBottomClass="pb-70"
              bannerData={
                bussinessData?.homepage_configurations
                  ?.homepage_featured_product
              }
            /> */}
            <BannerThree
              // spaceTopClass="pt-95"
              spaceBottomClass="pb-70"
              // spaceBottomClass="pb-100"
              bannerData={
                bussinessData?.homepage_configurations
                  ?.homepage_featured_product
              }
            />
            {/* newsletter */}
            {/* <NewsletterTwo
              spaceBottomClass="pb-100"
              subscribeBtnClass="pink-subscribe"
            /> */}
          </LayoutTwo>
        </Fragment>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default HomeFlowerShop;
