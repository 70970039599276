import React from "react";
import { ReactComponent as IncreaseIcon } from "../../assets/svgs/increaseCircleCart.svg";
import { ReactComponent as DecreaseIcon } from "../../assets/svgs/decreseSvgCart.svg";
interface IProductQuantity {
  onPressIncrease: (cartItem) => void;
  onPressDecrease: (cartItem) => void;
  quantity: number | string;
  itemObject?: any;
  fromCart?: boolean;
}
const ProductQuantity: React.FC<IProductQuantity> = (
  props: IProductQuantity
) => {
  const { onPressIncrease, onPressDecrease, itemObject, quantity, fromCart } =
    props;
  return (
    <>
      <div className="item-quantity d-none d-sm-flex">
        <span onClick={() => onPressIncrease(itemObject)}>
          <IncreaseIcon />
        </span>
        <span className="item-quantity-text"> {quantity}</span>
        <span onClick={() => onPressDecrease(itemObject)}>
          <DecreaseIcon />
        </span>
      </div>
      {
        // mobile view
        <div
          className={`item-quantity ${!fromCart ? "mobile-input" : ""} d-lg-none`}
        >
          <span onClick={() => onPressIncrease(itemObject)}>
            <IncreaseIcon />
          </span>
          <span
            className={`item-quantity-text  ${!fromCart ? "mobile-text" : ""} `}
          >
            {" "}
            {quantity}
          </span>
          <span onClick={() => onPressDecrease(itemObject)}>
            <DecreaseIcon />
          </span>
        </div>
        // mobile view
      }
    </>
  );
};

export default ProductQuantity;
