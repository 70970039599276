import React from "react";

const WhatsappContact: React.FC = () => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 119 119"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_9568_3550)">
        <path
          d="M27 88L31.5941 71.3175C28.7538 66.4209 27.2638 60.8755 27.2794 55.2065C27.2794 37.4427 41.8066 23 59.6397 23C68.3001 23 76.4329 26.3519 82.5324 32.438C88.6475 38.524 92.0155 46.6181 92 55.222C92 72.9858 77.4727 87.4285 59.6241 87.4285H59.6086C54.192 87.4285 48.8684 86.0692 44.1347 83.505L27 88ZM44.9572 77.6816L45.935 78.2685C50.0635 80.7091 54.7973 81.9912 59.6241 82.0067H59.6397C74.4618 82.0067 86.5367 70.0045 86.5367 55.2374C86.5367 48.0856 83.743 41.3662 78.6678 36.2997C73.5926 31.2331 66.8257 28.4527 59.6397 28.4527C44.8176 28.4373 32.7426 40.4394 32.7426 55.2065C32.7426 60.2576 34.155 65.1851 36.8555 69.4484L37.4919 70.4679L34.7758 80.3384L44.9572 77.6816Z"
          fill="white"
        />
        <path
          d="M28.1326 86.8722L32.5714 70.7613C29.8243 66.05 28.3809 60.69 28.3809 55.2218C28.3964 38.0759 42.4114 24.1274 59.6392 24.1274C68.0048 24.1274 75.8427 27.3713 81.7405 33.241C87.6383 39.1108 90.882 46.9269 90.882 55.2372C90.882 72.3832 76.8515 86.3316 59.6392 86.3316H59.6237C54.3933 86.3316 49.256 85.0186 44.693 82.5471L28.1326 86.8722Z"
          fill="#2DC76D"
        />
        <path
          d="M27 88L31.5941 71.3175C28.7538 66.4209 27.2638 60.8755 27.2794 55.2065C27.2794 37.4427 41.8066 23 59.6397 23C68.3001 23 76.4329 26.3519 82.5324 32.438C88.6475 38.524 92.0155 46.6181 92 55.222C92 72.9858 77.4727 87.4285 59.6241 87.4285H59.6086C54.192 87.4285 48.8684 86.0692 44.1347 83.505L27 88ZM44.9572 77.6816L45.935 78.2685C50.0635 80.7091 54.7973 81.9912 59.6241 82.0067H59.6397C74.4618 82.0067 86.5367 70.0045 86.5367 55.2374C86.5367 48.0856 83.743 41.3662 78.6678 36.2997C73.5926 31.2331 66.8257 28.4527 59.6397 28.4527C44.8176 28.4373 32.7426 40.4394 32.7426 55.2065C32.7426 60.2576 34.155 65.1851 36.8555 69.4484L37.4919 70.4679L34.7758 80.3384L44.9572 77.6816Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M51.5529 41.735C50.9476 40.3911 50.3113 40.3602 49.737 40.3448C49.2714 40.3293 48.7282 40.3293 48.185 40.3293C47.6418 40.3293 46.7726 40.5302 46.0276 41.3334C45.2826 42.1366 43.2029 44.0829 43.2029 48.0527C43.2029 52.0071 46.1052 55.8379 46.5088 56.3785C46.9123 56.9192 52.1117 65.3068 60.322 68.5352C67.1511 71.2229 68.5479 70.6823 70.0224 70.5432C71.4968 70.4042 74.8027 68.5969 75.4856 66.7124C76.153 64.8279 76.153 63.2215 75.9512 62.8816C75.7494 62.5418 75.2062 62.341 74.3991 61.9394C73.5921 61.5378 69.6188 59.5915 68.8738 59.3134C68.1288 59.0508 67.5856 58.9118 67.0579 59.715C66.5147 60.5183 64.9627 62.3256 64.497 62.8662C64.0314 63.4068 63.5503 63.4686 62.7432 63.067C61.9362 62.6654 59.3287 61.8158 56.2401 59.0663C53.8344 56.9346 52.2048 54.2932 51.7392 53.49C51.2736 52.6868 51.6926 52.2543 52.0961 51.8526C52.4531 51.4974 52.9032 50.9104 53.3067 50.447C53.7103 49.9836 53.85 49.6438 54.1138 49.1031C54.3777 48.5625 54.2535 48.0991 54.0517 47.6975C53.85 47.3113 52.2669 43.326 51.5529 41.735Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_9568_3550"
          x="0"
          y="0"
          width="119"
          height="119"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="13.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_9568_3550"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_9568_3550"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default WhatsappContact;
