import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  FooterFacebook,
  FooterInstagram,
  FooterTiktok,
  WhatsAppSvg,
} from "../../../assets/svgs/svgs";
const MobileWidgets = () => {
  const { bussinessData } = useSelector((state) => state.bussinessData);
  const { t } = useTranslation();
  return (
    <div className="offcanvas-widget-area pl-20 pr-20">
      <div className="off-canvas-contact-widget ">
        <div className="header-contact-info">
          {bussinessData && Object.keys(bussinessData).length !== 0 && (
            <ul className="header-contact-info__list">
              <li>
                <i className="fa fa-phone"></i>{" "}
                <a
                  href={`tel://${bussinessData.site_settings?.siteCallUsPhoneNumber}`}
                >
                  <span dir="ltr">
                    {bussinessData.site_settings?.siteCallUsPhoneNumber}
                  </span>
                </a>
              </li>
              {/* <li>
              <i className="fa fa-envelope"></i>{" "}
              <a href="mailto:info@yourdomain.com">info@yourdomain.com</a>
            </li> */}
            </ul>
          )}
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}

      <div className="off-canvas-widget-social">
        {bussinessData?.socialLinks?.fb && (
          <a href={bussinessData?.socialLinks?.fb} title="Facebook">
            <i className="">
              <FooterFacebook />
            </i>
          </a>
        )}
        {bussinessData?.socialLinks?.insta && (
          <a href={bussinessData?.socialLinks?.insta} title="Instagram">
            <FooterInstagram className="mr-10 ml-10" />
          </a>
        )}
        {bussinessData?.socialLinks?.tiktok && (
          <a href={bussinessData?.socialLinks?.tiktok} title="tiktok">
            <i class="fa fa-tiktok">
              <FooterTiktok />
            </i>
          </a>
        )}
        {bussinessData?.socialLinks?.whatsapp && (
          <a href={bussinessData?.socialLinks?.whatsapp} title="Facebook">
            <WhatsAppSvg />
          </a>
        )}
      </div>
      {/*Off Canvas Widget Social End*/}
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default MobileWidgets;
