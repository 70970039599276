import ReactGA4 from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";
import { ICUSTOMERINFO } from "../shared/commonUtils";
import { getBussinessData } from "../shared/helpersMethod";
import { hashData } from "./hashHelper";
export const TrackGoogleAnalyticsEvent = (
  category: any,
  event_name: string | UaEventOptions,
  label: any,
  data: any
) => {
  console.log("GA event:", category, ":", event_name, ":", label);

  let event_params = {
    // category,
    // label,
    // event_modal:data,
    ...data,
  };
  // Send GA4 Event
  ReactGA4.event(event_name, event_params);
};
export const getReqJsonModalGA4 = (
  bussinessName: string,
  status: string,
  reason: string,
  apiurl: string,
  reqData?: any
) => {
  var jsonBody = {
    resto_name: bussinessName,
    status: status,
    reason: reason,
    apiurl: apiurl,
    reqData: reqData,
  };
  return jsonBody;
};
export const getReqJsonModalGA4WithoutReqData = (
  //   bussinessName: string,
  //   status: string,
  //   reason: string,
  //   apiurl: string,
  pageType: string,
  productType: string | undefined,
  reqData?: any,
  customerData?: ICUSTOMERINFO,
  finalAddress?: string,
  bussinessData?: any
) => {
  // const bussinessData = getBussinessData();
  var customerJson = {};
  var userAddress = {};
  if (customerData && bussinessData) {
    customerJson = {
      id: 0,
      order_count: 1,
      // total_spent: 78,
      first_name: customerData?.name,
      last_name: "",
      billing_first_name: customerData?.name,
      billing_last_name: "",
      billing_company: "",
      billing_address_1: finalAddress,
      billing_address_2: "",
      billing_city: bussinessData.businessCountry.country_name,
      billing_postcode: "",
      billing_country: bussinessData.businessCountry.country_name,
      billing_state: bussinessData.businessCountry.country_name,
      billing_email: "",
      billing_email_hash: "",
      billing_phone: customerData?.mobile_number,
      shipping_firstName: customerData?.name,
      shipping_lastName: "",
      shipping_company: "",
      shipping_address_1: finalAddress,
      shipping_address_2: "",
      shipping_city: bussinessData.businessCountry.country_name,
      shipping_postcode: "",
      shipping_country: bussinessData.businessCountry.country_name,
      shipping_state: bussinessData.businessCountry.country_name,
    };
    userAddress = {
      sha256_email_address: "",
      sha256_phone_number: hashData(customerData?.mobile_number),
      address: {
        sha256_first_name: hashData(customerData?.name),
        sha256_last_name: "",
        street: finalAddress,
        city: bussinessData.businessCountry.country_name,
        region: bussinessData.businessCountry.country_name,
        postal_code: "",
        country: bussinessData.businessCountry.country_name,
      },
    };
  }
  var jsonBody = {
    pageType: pageType,
    productType: productType,
    ecommerce: {
      ...reqData,
      customer: customerData !== undefined ? { ...customerJson } : undefined,
    },
    new_customer: false,
    user_data: customerData !== undefined ? { ...userAddress } : undefined,
  };
  return jsonBody;
};
