import { EffectFade } from "swiper";
import Swiper, { SwiperSlide } from "../../components/swiper";
import HeroSliderThreeSingle from "../../components/hero-slider/HeroSliderThreeSingle.js";
import React, { useEffect, useState } from "react";
import { IHomePageSlider, Slide } from "../../shared/commonUtils";
const params = {
  //  effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative' | 'cards';
  effect: "fade",
  // fadeEffect: {
  //   crossFade: true,
  // },
  autoplay: true,
  modules: [EffectFade],
  loop: true,
  speed: 1000,
  pagination: true,
  // dir: "rtl",
  navigation: false,
  autoHeight: false,
};
interface IHeroSliderThree {
  homePageSlider: Slide[];
}
const HeroSliderThree: React.FC<IHeroSliderThree> = (
  props: IHeroSliderThree
) => {
  const [loading, setLoading] = useState<boolean>(true);
  const { homePageSlider } = props;
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, []);
  return (
    <>
      {homePageSlider && (
        <div className="slider-area">
          <div className="slider-active nav-style-1">
            {/* <HeroSliderThreeSingle data={{}} /> */}
            <Swiper options={params}>
              {homePageSlider?.map((single, key) => (
                <SwiperSlide key={key}>
                  <HeroSliderThreeSingle data={single} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
};

export default HeroSliderThree;
