import React from "react";
import useTranslationHook from "../../hooks/useTranslationHook";
interface IApplyCoupon {
  hideCouponText?: boolean;
}
const ApplyCoupon: React.FC<IApplyCoupon> = (props: IApplyCoupon) => {
  const { hideCouponText } = props;
  const { t } = useTranslationHook();
  return (
    <div className={`promo-code ${hideCouponText && "hide-border-top"}`}>
      {!hideCouponText && <span className="promo-text">{t("have_promo")}</span>}
      <div className="coupon-input-box">
        <input placeholder={t("txt_enter_coupon")} />
        <button>{t("txt_apply_coupon")}</button>
      </div>
    </div>
  );
};

export default ApplyCoupon;
