import React from "react";
interface IARROWLEFT {
  dimension?: string;
}
const ArrowLeft: React.FC<IARROWLEFT> = (props: IARROWLEFT) => {
  const { dimension } = props;
  return (
    <svg
      width="12"
      height="22"
      viewBox="0 0 12 22"
      fill="none"
      style={{ rotate: dimension === "right" ? "180deg" : "0deg" }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7_21)">
        <path
          d="M8.48176 11.0234C8.27745 10.8785 8.14717 10.8117 8.0497 10.7128C5.56142 8.21937 3.07969 5.71932 0.588598 3.22869C0.14155 2.78158 -0.10681 2.27893 0.0450182 1.64451C0.299002 0.584627 1.56236 0.149755 2.42084 0.818066C2.53143 0.903721 2.62984 1.00726 2.72918 1.10704C5.61297 4.00242 8.49582 6.89875 11.3787 9.79413C12.209 10.629 12.2081 11.4376 11.374 12.2753C8.4752 15.1886 5.57548 18.1019 2.67482 21.0123C2.18185 21.5074 1.56517 21.627 0.969105 21.3606C0.392722 21.1027 0.0478296 20.5276 0.0862551 19.8847C0.112496 19.4489 0.328054 19.1138 0.629835 18.8126C3.10969 16.3276 5.58579 13.8379 8.0647 11.352C8.15561 11.2606 8.26714 11.191 8.48269 11.0234H8.48176Z"
          fill="#003B71"
        />
      </g>
      <defs>
        <clipPath id="clip0_7_21">
          <rect
            width="12"
            height="21"
            fill="white"
            transform="matrix(-1 0 0 -1 12 21.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowLeft;
