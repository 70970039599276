import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SectionTitleThree from "../../components/section-title/SectionTitleThree";
import ProductGridTwo from "./ProductGridTwo";
import { useEffect, useState } from "react";
import { Product } from "../../shared/commonUtils";
import ProductGridThree from "./ProductGridThree";

const TabProductFour = ({
  spaceBottomClass,
  category,
  productTabClass,
  homepageCarouselSlider,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>("");
  const [selectedTabProducts, setSelectedTabProducts] = useState<Product[]>([]);
  const switchTab = (selectedTab: any) => {
    setSelectedTab(selectedTab.title);
    setSelectedTabProducts(selectedTab.products);
  };
  useEffect(() => {
    if (homepageCarouselSlider && homepageCarouselSlider.length !== 0) {
      setSelectedTab(homepageCarouselSlider[0].title);
      setSelectedTabProducts(homepageCarouselSlider[0].products);
    }
  }, []);
  return (
    <div className={clsx("product-area", spaceBottomClass)}>
      <div className="container">
        <SectionTitleThree
          titleText="Featured Product"
          positionClass="text-center"
        />
        <Tab.Container defaultActiveKey="bestSeller">
          <Nav
            variant="pills"
            className={clsx(
              "product-tab-list pt-35 pb-60 text-center",
              productTabClass
            )}
          >
            {homepageCarouselSlider.map((item, index) => {
              return (
                <Nav.Item onClick={() => switchTab(item)}>
                  <Nav.Link
                    eventKey={selectedTab}
                    active={item.title === selectedTab}
                  >
                    <h4>{item.title}</h4>
                  </Nav.Link>
                </Nav.Item>
              );
            })}
            {/* <Nav.Item>
              <Nav.Link eventKey="newArrival">
                <h4>New Arrivals</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="bestSeller">
                <h4>Best Sellers</h4>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="saleItems">
                <h4>Sale Items</h4>
              </Nav.Link>
            </Nav.Item> */}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey={selectedTab} active>
              <div className="row">
                <ProductGridThree
                  selectedTabProducts={selectedTabProducts}
                  category={category}
                  type="new"
                  limit={10}
                  spaceBottomClass="mb-35"
                />
              </div>
            </Tab.Pane>
            {/* <Tab.Pane eventKey="newArrival">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="new"
                  limit={8}
                  spaceBottomClass="mb-25"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="bestSeller">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="bestSeller"
                  limit={8}
                  spaceBottomClass="mb-25"
                />
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey="saleItems">
              <div className="row">
                <ProductGridTwo
                  category={category}
                  type="saleItems"
                  limit={8}
                  spaceBottomClass="mb-25"
                />
              </div>
            </Tab.Pane> */}
          </Tab.Content>
        </Tab.Container>
        <div className="view-more text-center mt-20 toggle-btn6 col-12">
          <Link
            className="loadMore6"
            to={process.env.PUBLIC_URL + "/shop-grid-standard"}
          >
            VIEW MORE PRODUCTS
          </Link>
        </div>
      </div>
    </div>
  );
};

TabProductFour.propTypes = {
  category: PropTypes.string,
  spaceBottomClass: PropTypes.string,
};

export default TabProductFour;
