import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinks";
import { useDispatch, useSelector } from "react-redux";
import useGetToken from "../useGetToken";
import { setBussinessData } from "../../store/slices/bussiness-data-slice";
import {
  IALLPRODUCTS,
  IBRANDLOGO,
  ICATEGORY,
  IHOMEPAGEData,
  IHomePageSlider,
  IRESAllData,
} from "../../shared/commonUtils";
import { httpPost, httpPostWithAuth } from "../../axios/axiosUtils";
import {
  TOKEN_EXPIRIES_MESSAGE,
  UpdateBussinessInfo,
  getBussinessData,
  getUserPrefersLang,
} from "../../shared/helpersMethod";
import { increaseCounter, resetCounter } from "../../store/slices/apiCallCount";
// import cogoToast from "cogo-toast";
// import userChangeLanguageTrigger from "../useChangeLanguageTrigger";
const useGetHomePage = () => {
  const [loading, setLoading] = useState(false);
  const { getToken } = useGetToken();
  // const {changeLanguageTrigger} = userChangeLanguageTrigger();
  const [homePageSlider, setHomePageSider] = useState<IHomePageSlider[]>([]);
  const [homePageBrandLogo, setHomeBrandLogo] = useState<IBRANDLOGO[]>([]);
  const dispatch = useDispatch();
  const userLanguage = getUserPrefersLang();
  const queryParamLang = `?lang=${userLanguage}`;
  const [homePageCategories, setHomePageCategories] = useState<ICATEGORY[]>([]);
  const [homePageProducts, setHomePageProducts] = useState<IALLPRODUCTS[]>([]);
  const [homePageAllData, setHomePageAllData] = useState<IHOMEPAGEData>();
  const [homePageBanner, setHomePageBanner] = useState();

  // IALLPRODUCTS
  // const {getUserToken,userToken} = useBussiness();
  // "theme":"1","template":"1", "page":"home","element":"slider"
  const getHomePageSlider = async (
    theme: string,
    template: string,
    page: string,
    element: string
  ) => {
    setLoading(true);
    try {
      const res = await httpPostWithAuth(
        URL_CONSTANTS.get("URL_Get_Home")!,
        {
          theme,
          template,
          page,
          element,
        },
        ""
      );
      setHomePageSider(res);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const getHomePageBrands = async (
    theme: string,
    template: string,
    page: string,
    element: string
  ) => {
    setLoading(true);
    try {
      const res = await httpPostWithAuth(
        URL_CONSTANTS.get("URL_Get_Home")!,
        {
          theme,
          template,
          page,
          element,
        },
        ""
      );
      setHomeBrandLogo(res);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const getHomePageBanners = async () => {
    setLoading(true);
    const bussinessToken = getBussinessData();
    try {
      const res = await httpPost(
        URL_CONSTANTS.get("URL_Get_BANNERS")!,
        {},
        undefined,
        bussinessToken.accessToken
      );
      if (res.type === "success") {
        setHomePageBanner(res.data);
      }
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  const getHomePageCategory = async (
    theme: string,
    template: string,
    page: string,
    element: string
  ) => {
    setLoading(true);
    try {
      const res = await httpPostWithAuth(
        URL_CONSTANTS.get("URL_Get_Home")!,
        {
          theme,
          template,
          page,
          element,
        },
        ""
      );

      setHomePageCategories(res);
      setLoading(false);
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  // getHomePageProducts
  const getHomePageProducts = async () =>
    // theme:string,
    // template:string,
    // page:string,
    // element:string

    {
      const bussinessToken = getBussinessData();
      if (bussinessToken !== null) {
        setLoading(true);
        try {
          // bussinessToken.accessToken
          const res = await httpPostWithAuth(
            URL_CONSTANTS.get("URL_Get_Products")! +
              `${userLanguage !== null ? queryParamLang : ""}`,
            {},
            bussinessToken.accessToken
          );

          if (res.token === "expired") {
            // getToken().then(()=>{
            // getHomePageProducts('1','1','home','products');
            // });
          } else {
            // var tempData = [];
            // res.data.forEach((element:any,index:any) => {
            //     if(index % 2 === 1){
            //       res.data[index] = {...element,discount:null}
            //     }
            // });
            // console.log(res.data)
            setHomePageProducts(res.data);
          }
          setLoading(false);
        } catch (error: any) {
          console.log(error.message);
          setLoading(false);
        }
      }
    };
  const getHomePageAllData = async () =>
    // theme:string,
    // template:string,
    // page:string,
    // element:string

    {
      const bussinessToken = getBussinessData();
      // const {counter} = useSelector((state)=>state.apiCallCounter);
      if (bussinessToken !== null) {
        setLoading(true);
        try {
          // bussinessToken.accessToken
          const res: IRESAllData = await httpPostWithAuth(
            URL_CONSTANTS.get("URL_Get_HomePageAllData")! +
              `${userLanguage !== null ? queryParamLang : ""}`,

            {},

            bussinessToken.accessToken
          );
          // if (res.type === "error" && TOKEN_EXPIRIES_MESSAGE.includes(res.message)) {
          if (res.type === "error" && res?.token == "expired") {
            // cogoToast.error(res.message)
            getToken().then(() => {
              getHomePageAllData();
            });
          }
          if (res.data && res.type !== "error") {
            // changeLanguageTrigger(res.data.defaultLang)
            setHomePageAllData(res.data);
            dispatch(setBussinessData(res.data));
            UpdateBussinessInfo(res.data);

            // setHomePageProducts(res.data)
          }
          setLoading(false);
        } catch (error: any) {
          console.log(error.message);
          // cogoToast.error('something went wrong')
          setLoading(false);
        }
      }
    };

  const attemptApiCall = async (accessToken: string) => {
    return httpPostWithAuth(
      URL_CONSTANTS.get("URL_Get_HomePageAllData")! +
        `${userLanguage !== null ? queryParamLang : ""}`,
      {},
      accessToken
    );
  };

  const handleApiResponse = (res: IRESAllData) => {
    if (res.token === "expired") {
      getToken().then(() => {
        // Retry the API call after getting a new token
        attemptApiCall(getBussinessData().accessToken).then(handleApiResponse);
      });
    } else {
      setHomePageAllData(res.data);
      dispatch(setBussinessData(res.data));
      dispatch(resetCounter());
    }
  };
  return {
    getHomePageCategory,
    homePageCategories,
    homePageProducts,
    homePageBanner,
    getHomePageBanners,
    homePageSlider,
    getHomePageProducts,
    homePageAllData,
    getHomePageAllData,
    homePageBrandLogo,
    getHomePageSlider,
    getHomePageBrands,
    loading,
  };
};
export default useGetHomePage;
