import PropTypes from "prop-types";
// import Swiper, { SwiperSlide } from "../../components/swiper";
import React, { useEffect } from "react";
import { HomepageCategory } from "../../shared/commonUtils";
import { useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Link } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useReduxMethods";
import { setBreadCrumCategoryName } from "../../store/slices/breadCrums";
// effect?: 'slide' | 'fade' | 'cube' | 'coverflow' | 'flip' | 'creative' | 'cards';
// swiper slider settings
interface ICategoryOneSlider {
  homePageCategories: HomepageCategory[];
  spaceBottomClass: any;
  topSpaceClass: any;
}
const CategoryOneSlider: React.FC<ICategoryOneSlider> = (
  props: ICategoryOneSlider
) => {
  const { homePageCategories, topSpaceClass } = props;
  const dispatch = useAppDispatch();
  const swiper = useSwiper();
  useEffect(() => {
    if (swiper) {
      setTimeout(() => {
        swiper.slideTo(2);
      }, 4000);
    }
  }, [swiper]);
  const updateCategoryBreadCrumbs = (item: any) => {
    dispatch(
      setBreadCrumCategoryName({
        name: item.name,
        id: item.id!,
        slug: item.slug,
      })
    );
  };
  return (
    <>
      {/* <h1 className="heading">Flower Gallery</h1> */}
      {/* swiper js  */}
      <div
        className={`row justify-content-center ${topSpaceClass} ml-10  mr-10`}
      >
        {homePageCategories &&
          homePageCategories.map((single, key) => (
            <div
              className={`new-featuerd-categories element-${key}
              col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6
               `}
            >
              <Link
                onClick={() => updateCategoryBreadCrumbs(single)}
                to={`/collections/${single.slug}/${single.id}`}
              >
                <img
                  className={`new-featuerd-categories-img image-${key}`}
                  src={single.image}
                  alt={"slide_image-" + key}
                />
              </Link>
            </div>
          ))}
      </div>

      {/* <div className={clsx("collections-area", spaceBottomClass)}>
        <div className="container">
          <div className="collection-wrap-2 ">
            <div className="collection-active-2 ">
              {homePageCategories && (
                <Swiper
                  options={settings}
                  // containerClassName={"swiper_container-3d"}
                >
                  {homePageCategories.map((single, key) => (
                    <SwiperSlide
                      key={key}
                      // className="swiper-slid-3d"
                    >
                      <CategoryOneSingle data={single} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

CategoryOneSlider.propTypes = {
  spaceBottomClass: PropTypes.string,
};

export default CategoryOneSlider;
