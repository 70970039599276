// 

import { useState } from "react";
import { URL_CONSTANTS } from "../../axios/urlLinks";
import {  ISEARCHEDDATA } from "../../shared/commonUtils";
import {  httpPost } from "../../axios/axiosUtils";
import { getBussinessData, getUserPrefersLang } from "../../shared/helpersMethod";

const useGetSearchData = () => {
  const [loading, setLoading] = useState(false);
  const   bussinessData = getBussinessData();
  const userLanguage = getUserPrefersLang();
  const queryParamLang = `&lang=${userLanguage}`
  const [serachData,setSearchData] = useState<ISEARCHEDDATA[]>([])
  const getSearchResult= async (keyword:string) => {
    setLoading(true);
    try {
      //@ts-ignore
      if (keyword == ''){
        setSearchData([])
          return
      }
      const res = await httpPost(URL_CONSTANTS.get('URL_Search_Data')! +`?keywords=${keyword}${userLanguage !== null ? queryParamLang : ''}`,{},{},bussinessData.accessToken);
      // /rjs/business/by/name/ncd
      if(res.type == 'success'){
        if (res.data){
        setSearchData(res.data.searchData)
        return;
        }
        if (!res.data){
          setSearchData([])
          return;
          }
        
      }
    //   if(res.data === null){
    //     setSearchData([])
    // }
      setLoading(false);
     
    } catch (error: any) {
      console.log(error.message);
      setLoading(false);
    }
  };
  return {
    getSearchResult,
    serachData,
    loading,
  };
};
export default useGetSearchData;
