import PropTypes from "prop-types";
import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useAppSelector } from "../hooks/useReduxMethods";
interface ISEO {
  title?: string;
  titleTemplate?: string;
  description?: string;
  canonicalLink?: string;
}
const SEO: React.FC<ISEO> = ({ title, canonicalLink }) => {
  const { bussinessData } = useAppSelector((state) => state.bussinessData);

  return (
    // <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        {`${
          bussinessData?.site_settings?.siteTitle !== null &&
          bussinessData?.site_settings?.siteTitle !== ""
            ? bussinessData?.site_settings?.siteTitle
            : title
        }`}
      </title>
      {canonicalLink && <link rel="canonical" href={canonicalLink} />}
    </Helmet>
    // </HelmetProvider>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  titleTemplate: PropTypes.string,
  description: PropTypes.string,
};

SEO.defaultProps = {
  title: "Dukany ",
  titleTemplate: "Product Page",
  description: "Product page",
};

export default SEO;
