import {createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IHOMEPAGEData } from "../../shared/commonUtils";

// const {  } = require('@reduxjs/toolkit');

// const initialState: IHOMEPAGEData = {} as IHOMEPAGEData;

const bussinessDataSlice = createSlice({
    
    name: "bussinessData",
    initialState:{
        bussinessData:{} as IHOMEPAGEData,
    },
    reducers: {
        setBussinessData :(state, action: PayloadAction <IHOMEPAGEData>) => {
            console.log(state.bussinessData);
            const bussinessData1  = action.payload;
            // if(bussinessData1){
                  state.bussinessData= bussinessData1 ;
            // }
        }
    },
});

export const { setBussinessData } = bussinessDataSlice.actions;
export default bussinessDataSlice.reducer;
