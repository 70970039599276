import PropTypes from "prop-types";
import clsx from "clsx"
import { Link } from "react-router-dom";
const BannerThreeSingle = ({ data, spaceBottomClass }) => {
   {/* <img 
      style={{backgroundImage:`url(${data.image})`,width:576,height:351}}
      /> */}
  const redirectionLink = `${`/collections?q=filter&filter=${data?.conditions?.filter}&minprice=${data?.conditions?.minPrice}&maxprice=${data?.conditions?.maxPrice}`}`
  return (
  
    // <div className={clsx("single-banner-2", spaceBottomClass) }>
    //   <div
    //   className={`single-banner-2-bg ${currentLang === 'ar' && 'single-banner-2-bg-ar'}`}/>
     
    //   <div>
    //   <Link to={'/product/' + data.id}>
    //  <div className={`single-banner-2-img-new ${currentLang === 'ar' && 'banner-content-2-img-ar'}`} style={{backgroundImage:`url(${data.image})`}}/>
    //    </Link>
    
    //   <div className={`banner-content-2 ${currentLang === 'ar' && 'banner-content-2-ar'}`}>
    //     <h3>{data.name}</h3>
    //     <h4>
    //     {t('starting_at')} <span>{data.price}</span>
    //     </h4>
    //     <Link to={'/product/' + data.id}>
    //       <i className="fa fa-long-arrow-right" />
    //     </Link>
    //   </div>
     
    //   </div>
    // </div>
    
    <div className={clsx("single-banner-2", spaceBottomClass)}>
      <Link to={redirectionLink}>
        <img src={process.env.PUBLIC_URL + data.image} alt={'featured-product'} />
      </Link>
      {/* <div className="banner-content-2">
        <h3>{data.title}</h3>
        <h4>
          {data.description} <span>{data.price}</span>
        </h4>
        <div></div>
        <Link to={redirectionLink}>
          <i className="fa fa-long-arrow-right" />
        </Link>
      </div> */}
    </div>
    // <div className={clsx("single-banner-2", spaceBottomClass)} >
     
    //    <Link to={redirectionLink}>
    //     <img src={process.env.PUBLIC_URL + data.image} alt="" 
      
    //     />
    //   </Link>
    //   <div className="banner-content-2 ">
    //     <h3>{data.title}</h3>
    //     <h5>
    //       {data.description}
    //     </h5>
    //     <Link to={redirectionLink}>
    //       <i className="fa fa-long-arrow-right" />
    //     </Link>
       
    //   </div>
    // </div>
//     <>
      
//         <div className="single-banner-2-img" style={{background:'linear-gradient(to bottom right top,#99D5FC,#daedff,#E9F4FF)'}}/>
//         <div className={clsx("single-banner-2", spaceBottomClass)}>
//  <Link to={'/product/' + data.id}>
//       <div className="single-banner-2-img-new" style={{backgroundImage:`url(${data.image})`}}/>
//       </Link>
//       <div className="banner-content-2 ">
//         <h3>{data.name}</h3>
//         <h5>
//           {'Starting at'} <span>{data.price}</span>
//         </h5>
       
       
//       </div>
       
//     </div>
//     <Link to={process.env.PUBLIC_URL + data.link}>
//     <i className="fa fa-long-arrow-right" />
//         </Link>
//    </>
  );
};

BannerThreeSingle.propTypes = {
  data: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string
};

export default BannerThreeSingle;
